import Navbar from 'components/navbar'
import { ProductMedia } from 'components/product-media'
import { ProductTabs } from 'components/product-tabs'
import { Helmet } from 'react-helmet'
import style from 'styles/pages/products.module.scss'
import { useIsScreenMaxWidth } from 'utils/media-query'

export default function MatchingEngine() {
  const isMax1023 = useIsScreenMaxWidth(1023)

  return (
    <>
      <Helmet>
        <title>Matching Engine | H+ Trading Solutions</title>
        <meta name="description" content="Transforming Data into Trading Mastery" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Navbar />

      <main>
        <div className={style.hero}>
          {!isMax1023 && <ProductTabs />}

          <div className={style.centerVideoCont}>
            <div className={style.centerVideoInner}>
              <iframe
                className={style.centerVideoIframe}
                src="https://customer-gqquxhxmrzcqw8ux.cloudflarestream.com/e1362f93749a5227794398bb8b839fc4/iframe?autoplay=true&muted=true&loop=true&controls=false"
              />
            </div>
          </div>

          <div className={style.heroContent}>
            <div>
              <h1>Matching Engine</h1>
              <p>
                HPlus Matching Engine is a powerful and flexible solution for modern trading
                platforms. Its support for advanced order types, high-performance capabilities,
                multi-asset compatibility, real-time settlement, and comprehensive audit and
                compliance features make it an ideal choice for organizations seeking to enhance
                their trading infrastructure and deliver an exceptional experience for their users.
              </p>
            </div>
            <div style={{ minWidth: 160, maxWidth: 160 }}>
              <ProductMedia
                images={[
                  '/matching-engine/1.png',
                  '/matching-engine/2.png',
                  '/matching-engine/3.png',
                ]}
                omitFromThumbs={['/matching-engine/2.png']}
              />
            </div>
          </div>
        </div>

        <div style={{ background: '#9FE870' }}>
          <div className={style.section}>
            <div>
              <img src="/matching-engine/order-types.png" />
            </div>
            <div>
              <h2>Advanced Order Types</h2>
              <p>
                HPlus Matching Engine boasts an extensive range of advanced order types right out of
                the box, including Stop Losses, Take Profits, Trailing Stop Losses, Hidden Orders,
                and Icebergs. This comprehensive selection empowers traders with greater flexibility
                and precision in executing their strategies, catering to diverse trading needs and
                preferences.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className={`${style.section} ${style.sectionReverse}`}>
            <div>
              <h2>Exceptional High-Performance Capability</h2>
              <p>
                Engineered for excellence, the HPlus Matching Engine is highly scalable, handling up
                to 1 million market actions per second across various instruments. Its
                lightning-fast response time of under 10 microseconds ensures a seamless and
                efficient trading experience, keeping pace with the dynamic demands of modern
                financial markets.
              </p>
            </div>
            <div>
              <img src="/matching-engine/performance.png" />
            </div>
          </div>
        </div>

        <div style={{ background: '#9E70E8' }}>
          <div className={style.section}>
            <div>
              <img src="/matching-engine/multi-asset.png" />
            </div>
            <div>
              <h2>Robust Multi-Asset Support</h2>
              <p>
                HPlus Matching Engine is designed with versatility in mind, allowing the integration
                of multiple asset classes, such as spot trading, futures, perpetual linear swaps,
                and inverse perpetual swaps, into a single order book. This innovative approach
                provides the flexibility to support both First-In-First-Out (FIFO) and Pro-Rata
                matching rules, tailored to different markets and tick sizes for optimal efficiency
                and liquidity.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className={`${style.section} ${style.sectionReverse}`}>
            <div>
              <h2>Cutting-Edge Real-Time Settlement</h2>
              <p>
                HPlus Matching Engine prioritizes security and compliance, offering full audit logs,
                trade monitoring, trade compliance, and real-time post-trade analysis. Additionally,
                periodic reports are generated to ensure adherence to regulatory requirements and
                maintain transparency. This comprehensive suite of tools empowers organizations to
                maintain a strong risk management framework, prevent market abuse, and foster a
                secure and compliant trading environment.
              </p>
            </div>
            <div>
              <img src="/matching-engine/settlement.png" />
            </div>
          </div>
        </div>

        <div style={{ background: '#9FE870' }}>
          <div className={style.section}>
            <div>
              <img src="/matching-engine/audit.png" />
            </div>
            <div>
              <h2>Comprehensive Audit and Compliance Features</h2>
              <p>
                Risk Reduce counterparty risk by segregating collateral from trading partners and
                exchanges held at HPlus. Our collateral management solution offers comprehensive
                reporting, ongoing collateral sufficiency monitoring, and segregated collateral
                accounts.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div
            className={`${style.section} ${style.sectionReverse}`}
            style={{ borderBottom: `1px solid #20252C` }}
          >
            <div>
              <h2>Seamless Integration and Customization</h2>
              <p>
                HPlus Matching Engine is designed for seamless integration with existing systems,
                ensuring minimal disruption during implementation. Its modular architecture allows
                for easy customization, enabling clients to adapt the engine to their specific
                business needs and requirements. This flexibility ensures that HPlus Matching Engine
                can be tailored to a wide range of use cases, from small-scale trading platforms to
                large-scale global exchanges.
              </p>
            </div>
            <div>
              <img src="/matching-engine/integration.png" />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
