import Navbar from 'components/navbar'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import style from 'styles/pages/jobs.module.scss'

export default function Recruiting() {
  return (
    <>
      <Helmet>
        <title>Jobs | H+ Trading Solutions</title>
        <meta name="description" content="Transforming Data into Trading Mastery" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Navbar />

      <main>
        <div className={style.content}>
          <h1 className={style.heading}>Jobs</h1>

          <Link to="/jobs/principal-smart-contract-developer" className={style.position}>
            <h4>Principal Smart Contract Developer (Remote)</h4>
            <p>
              Build from scratch, improve, and maintain a decentralized financial protocol that will
              integrate with an already established centralized platform that already provides
              various services around crypto spot, derivatives, and NFT trading and allow certain
              parts of that functionality to be utilized in a decentralized manner.
            </p>
          </Link>
        </div>
      </main>
    </>
  )
}
