import { api } from 'api/adapter'
import { Input } from 'components/input'
import Navbar from 'components/navbar'
import { Recaptcha } from 'components/recaptcha'
import { Textarea } from 'components/textarea'
import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'

import style from 'styles/pages/jobs-post.module.scss'

export default function JobsSmartContract() {
  return (
    <>
      <Helmet>
        <title>Principal Smart Contract Developer | H+ Trading Solutions</title>
        <meta name="description" content="Transforming Data into Trading Mastery" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Navbar />

      <main>
        <div className={style.content}>
          <h1 className={style.heading}>Principal Smart Contract Developer</h1>

          <h4>Job description:</h4>
          <p>
            Build from scratch, improve, and maintain a decentralized financial protocol that will
            integrate with an already established centralized platform that already provides various
            services around crypto spot, derivatives, and NFT trading and allow certain parts of
            that functionality to be utilized in a decentralized manner. The important parts of the
            protocol will be seeding non-arbitrageable randomness and minimizing gas costs and
            latency.
          </p>
          <h4>Required:</h4>
          <ul>
            <li>
              Good knowledge of internals of ERC-20, ERC-721, ERC-1155, and OpenSea Wyvern, and
              UniSwap exchange contracts
            </li>
            <li>
              Track record of developing smart contracts (doesn’t necessarily have to be EVM based)
            </li>
            <li>
              Broad familiarity with other popular smart contract blockchains like Solana, Tron,
              EOS, etc
            </li>
            <li>Strong approach to security and pen-testing</li>
            <li>
              Experience with seeding and exploiting predictable randomness within blockchain
              contracts
            </li>
          </ul>
          <h4>Good to have:</h4>
          <ul>
            <li>Experience running and maintaining production blockchain nodes</li>
            <li>
              Familiarity with Web3 front-end integration, e.g. dealing with Metamask, Phantom,
              TronLink, etc
            </li>
            <li>Some knowledge of CLOB and derivative DEXes like ApeX Pro, 1Inch, dYdX, or MDEX</li>
          </ul>
          <h4>Benefits:</h4>
          <ul>
            <li>
              Competitive salaries regardless of your physical location ($300k+ per year, depending
              on the value brought to the table)
            </li>
            <li>Salaries can be paid in cryptocurrency if preferred</li>
            <li>
              100% remote work and culture, we have been remote only for many years from the very
              beginning
            </li>
            <li>
              Build exciting & innovative projects that are used by tens of thousands of users on a
              daily basis
            </li>
            <li>Work with folks who are passionate about what they do</li>
          </ul>

          <h4>Interview Process:</h4>
          <ul>
            <li>Resume/portfolio review</li>
            <li>Preliminary voice or video interview</li>
            <li>
              Trial project with completion time of around a week that is fully compensated at a
              market rate
            </li>
            <li>Two follow up interviews that involve project review</li>
          </ul>
        </div>

        <h2 className={style.heading}>Apply Now</h2>
        <Form />
      </main>
    </>
  )
}

const Form = () => {
  const recaptchaRef = useRef<any>()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [apiError, setApiError] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<{
    first: string
    last: string
    email: string
    message: string
  }>()

  const onSubmit = async () => {
    setIsSubmitted(false)
    setIsSubmitting(true)
    await recaptchaRef.current.execute()
  }

  const onCaptchaVerify = async (token: string) => {
    const formValues = getValues()
    try {
      await api.post(
        '/api/contact',
        {
          ...formValues,
          interest: 'Position: Principal Smart Contract Developer',
          token,
        },
        { json: false },
      )
      reset()
      setIsSubmitted(true)
    } catch (err) {
      setApiError(err.message)
    }
    setIsSubmitting(false)
  }

  return (
    <>
      <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={style.flex}>
          <div>
            <label className={style.label}>First Name</label>
            <Input
              {...register('first', { required: true, minLength: 2 })}
              invalid={!!errors.first}
            />
            {errors.first && (
              <div className={style.error}>
                {errors.first.type === 'required'
                  ? 'This field is required'
                  : 'Must be at least 2 characters'}
              </div>
            )}
          </div>
          <div>
            <label className={style.label}>Last Name</label>
            <Input
              {...register('last', { required: true, minLength: 2 })}
              invalid={!!errors.last}
            />
            {errors.last && (
              <div className={style.error}>
                {errors.last.type === 'required'
                  ? 'This field is required'
                  : 'Must be at least 2 characters'}
              </div>
            )}
          </div>
        </div>

        <label className={style.label}>Email</label>
        <Input {...register('email', { required: true, minLength: 3 })} invalid={!!errors.email} />
        {errors.email && (
          <div className={style.error}>
            {errors.email.type === 'required'
              ? 'This field is required'
              : 'Must be at least 3 characters'}
          </div>
        )}

        <label className={style.label}>Message</label>
        <Textarea {...register('message')} />
        {apiError && <div className={style.error}>{apiError}</div>}
        {isSubmitted && (
          <div className={style.success}>
            Thank you for contacting us. We'll be in touch shortly.
          </div>
        )}

        <button className={style.submitButton} disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>

      <Recaptcha
        capRef={recaptchaRef}
        onVerify={onCaptchaVerify}
        onAbort={() => setIsSubmitting(false)}
      />
    </>
  )
}
