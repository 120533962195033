import Navbar from 'components/navbar'
import { Helmet } from 'react-helmet'
import style from 'styles/pages/tos-privacy.module.scss'

export default function TOS() {
  return (
    <>
      <Helmet>
        <title>Terms of Service | H+ Trading Solutions</title>
        <meta name="description" content="Transforming Data into Trading Mastery" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Navbar />

      <main>
        <h1 className={style.heading}>Terms of Service</h1>

        <div className={style.content}>
          <p>
            By using the HPlus Trading Solutions, you are agreeing to these terms (the “HPlus
            Terms”). Please read all the terms carefully, as they explain what we expect from you
            and what you can expect from us.
          </p>
          <p>
            These terms are between you and HPlus Consulting. When we speak of “HPlus Consulting,”
            “we,” “us,” or “our,” we mean HPlus Consulting or one of its affiliates that provide the
            HPlus Trading Solutions, which includes any subsidiary or affiliate of HPlus Consulting.
          </p>
          <p>
            If you are entering into these terms on behalf of a company or other legal entity or
            organization, you represent that you have the authority to bind the entity or
            organization to these terms.
          </p>
          <p>
            These terms are effective as of the first date that you use the HPlus Trading Solutions.
            The “HPlus Trading Solutions” include any products, services, and applications available
            on the HPlus Consulting platform.
          </p>
          <p>
            Capitalized terms used but not defined in these HPlus Terms shall have the meaning given
            to them in the HPlus Consulting User Agreement.
          </p>
          <h3>Acceptance of The HPlus Consulting Terms</h3>
          <p>
            By using the HPlus Trading Solutions, you are agreeing to these terms. Please read all
            the terms carefully, as they explain what we expect from you and what you can expect
            from us.
          </p>
          <p>
            By agreeing to the HPlus Consulting Arbitration Agreement, you agree to resolve disputes
            with us via binding arbitration or in small claims court (instead of a court of general
            jurisdiction), and you agree to do so as an individual (instead of as a member of a
            class in a class action). To the extent that the law allows, you also waive your right
            to a trial by jury. For more information, see the section in these terms entitled
            “Arbitration Agreement, Class Action Waiver, and Jury Trial Waiver.”
          </p>
          <p>
            It is also important that you review the “Indemnification; Limitation of Liability”
            section in these terms.
          </p>
          <p>
            Policies. Please also read our privacy policy, cookie policy, prohibited use policy, and
            any other terms that may be linked or referenced. By using the HPlus Trading Solutions,
            you are agreeing to these terms and policies as well as all applicable laws and
            regulations. If you do not agree to be bound by these terms, then you may not access or
            otherwise use the HPlus Trading Solutions.
          </p>
          <p>
            Entire Agreement. These HPlus Terms, privacy policy, prohibited use policy, and any
            other terms referenced within these terms, the API appendices, the HPlus Consulting User
            Agreement, and any other written agreement or contractual relationship between you and
            HPlus Consulting comprise the entire understanding and agreement between you and HPlus
            Consulting regarding the HPlus Trading Solutions and supersede any prior discussions,
            agreements, and understandings (including without limitation any prior versions of these
            terms) between you and HPlus Consulting in relation to the HPlus Trading Solutions. The
            HPlus Trading Solutions are the development applications, infrastructure tools, or other
            services provided by HPlus Consulting and listed on this page, including, but not
            limited to, HPlus Consulting's application programming interface (API) and any
            accompanying or related documentation, source code, executable applications, and other
            materials, data, or content obtained from or through an HPlus Consulting API, and any
            other services or resources available on the HPlus Consulting platform, which may be
            updated from time to time pursuant to these terms.
          </p>
          <p>
            Informational Purposes Only; No Advice. For the avoidance of doubt, the HPlus Trading
            Solutions do not include or provide financial, investment, tax, legal, regulatory,
            accounting, business, or other advice, and you are solely responsible for determining
            whether any development, investment, investment strategy, or related transaction is
            appropriate for you based on your personal investment objectives, financial
            circumstances, and risk tolerance. Information provided by HPlus Consulting on the
            website or otherwise (including links to any third-party sites or communications with
            HPlus Consulting representatives) does not constitute investment advice, financial
            advice, trading advice, legal advice, or any other sort of advice or recommendation, and
            you should not treat it as such. HPlus Consulting does not recommend that any digital
            asset should be bought, earned, sold, or held by you. HPlus Consulting will not be held
            responsible for the decisions you make to buy, sell, or hold any digital asset based on
            the information provided by HPlus Consulting.
          </p>
          <h3>User Representations and Warranties</h3>
          <p>You represent and warrant that:</p>
          <p>If you are an individual, you are of legal age to form a binding contract.</p>
          <p>
            If you’re agreeing to these terms on behalf of an entity or organization, you are
            authorized to agree to these terms on their behalf and bind them to these terms
            (references to “you” and “your” in these terms refer to that entity).
          </p>
          <p>
            You will comply with all applicable laws and regulations related to your use of the
            HPlus Trading Solutions and any actions or omissions associated with them or other HPlus
            Consulting services. We are not responsible for your use of the HPlus Trading Solutions
            in a way that violates the law or regulations.
          </p>
          <p>
            In connection with the HPlus Trading Solutions, you will comply with all applicable
            laws, including export restrictions, end-user restrictions, antiterrorism laws, and
            economic sanctions. You are not permitted to use the HPlus Trading Solutions if doing so
            would directly or indirectly violate any applicable laws, including economic or
            financial sanctions or trade embargoes.
          </p>
          <p>
            You represent that you are not subject to any Sanctions Laws and are not doing business
            with or on behalf of anyone who is, and you are not located or headquartered in a
            comprehensively sanctioned jurisdiction.
          </p>
          <p>
            If you are an entity, you have adequate controls and systems in place to screen
            transactions and ensure compliance with applicable Sanctions Laws.
          </p>
          <p>
            You will not cause HPlus Consulting to engage in transactions that would violate
            applicable laws and regulations, including Sanctions Laws and anti-money laundering,
            counter-terrorist financing, or anti-corruption laws and regulations.
          </p>
          <p>
            Upon request, you will provide HPlus Consulting with information and records required to
            satisfy its legal obligations.
          </p>
          <p>
            Nothing in this clause is to be construed as authorization by HPlus Consulting for you
            to violate these terms.
          </p>
          <p>
            You will promptly inform HPlus Consulting in writing if any of the above representations
            and warranties cease to be true.
          </p>
          <h3>Fees and Payment Terms</h3>
          <p>
            Paid Services: Fees for HPlus Trading Solutions are presented during signup and are
            subject to the payment terms in the applicable product-specific appendix. Payment terms
            presented during signup are considered part of these terms.
          </p>
          <p>
            Billing: A third-party payment processor may be used to bill your payment account for
            HPlus Trading Solutions. The processing of payments is subject to the terms, conditions,
            and privacy policies of the payment processor. By using HPlus Trading Solutions, you
            agree to pay through a payment processor according to applicable payment terms.
          </p>
          <p>
            Payment Method: Payment terms are based on your chosen payment method and may be
            determined by agreements with your financial institution, credit card issuer, or payment
            provider. If payment is not received, you agree to pay all amounts due upon demand.
          </p>
          <p>
            Recurring Billing: Some HPlus Trading Solutions may have an initial period with a
            one-time charge, followed by recurring charges. By choosing a recurring payment plan,
            you accept responsibility for all recurring charges until cancellation. We may submit
            periodic charges without further authorization, until you provide prior notice to
            terminate this authorization or change your payment method.
          </p>
          <p>
            Current Information Required: You must provide current, complete, and accurate
            information for your billing account and promptly update all information. If you fail to
            do so, we may continue charging you for any use of the services.
          </p>
          <p>
            Auto-Renewal: Unless terminated, HPlus Trading Solutions subscriptions will
            automatically renew at the then-current rate (subject to change at our discretion). You
            can change or cancel your subscription at any time in account settings. No prorated
            refunds will be given for the then-current subscription period.
          </p>
          <p>
            Reaffirmation of Authorization: Your continued use of the HPlus Trading Solutions
            reaffirms our authorization to charge your payment method. You are responsible for such
            charges and this does not waive our right to seek payment directly from you.
          </p>
          <p>
            Abusive Usage: HPlus Consulting may monitor usage for abuse or circumvention of
            restrictions. Actions may be taken against users found to be in violation, including
            billing for overages, requiring an upgrade, discontinuing use, or terminating access to
            the HPlus Trading Solutions.
          </p>
          <h3>Licenses and Ownership</h3>
          <p>
            HPlus Intellectual Property: All HPlus Trading Solutions, HPlus Consulting logo, name,
            and other related marks, along with associated intellectual property rights, remain the
            sole and exclusive property of HPlus Consulting. This includes copyrights, patents,
            trade dress, trade secrets, trademarks, and other proprietary rights.
          </p>
          <p>
            License Grant: HPlus Consulting grants you a limited, revocable, non-exclusive,
            non-transferable, and non-sublicensable license to access and use HPlus Trading
            Solutions, subject to these terms. Violation of these terms will result in automatic
            license termination.
          </p>
          <p>
            Third Party Software: HPlus Trading Solutions may include third party or open source
            software. Such software is subject to the terms of applicable licenses and limitations
            on liability under these terms.
          </p>
          <p>
            HPlus Marks License: With written consent, HPlus Consulting may grant you a limited,
            revocable, non-exclusive, non-transferable, and non-sublicensable license to use HPlus
            Marks for attribution purposes. You must not use HPlus Marks, or similar marks, as part
            of your application's name, logo, or branding.
          </p>
          <p>
            Limitation: Except as expressly granted in these terms, you have no right to use HPlus
            Intellectual Property. Both parties retain all rights to their respective intellectual
            property and proprietary rights.
          </p>
          <p>
            Third Party Content: HPlus API may contain third party content, which is the sole
            responsibility of the content provider. Access to this content may be restricted or
            limited in accordance with applicable laws and regulations.
          </p>
          <p>
            Public Statements: You cannot make statements suggesting partnership, sponsorship, or
            endorsement by HPlus Consulting without prior written approval.
          </p>
          <p>
            License Grant to HPlus: You grant HPlus Consulting a fully paid-up, royalty-free,
            non-exclusive, worldwide, perpetual, transferable, sublicensable, irrevocable right and
            license to use your brand and copyrighted material in connection with HPlus Trading
            Solutions and other HPlus Consulting services.
          </p>
          <p>
            Intellectual Property Representations: You warrant that your application and content do
            not infringe on HPlus Consulting's or any third party's intellectual property rights.
          </p>
          <p>
            License Upon Termination: Upon termination, HPlus Consulting will make reasonable
            efforts to remove references or links to your application.
          </p>
          <p>
            Digital Asset Control and Ownership: Control and ownership of digital assets remain with
            you or your end users. HPlus Consulting is not responsible for digital asset
            fluctuations or custody. You or your end users are responsible for safekeeping digital
            assets and associated private keys.
          </p>
          <h3>Termination</h3>
          <p>
            Termination Procedure: HPlus Consulting may immediately terminate or suspend these
            terms, any rights granted herein, or your license to HPlus Trading Solutions, in whole
            or in part, at its sole discretion, at any time, for any reason without prior notice.
            HPlus Consulting has the sole right to decide whether you are in violation of any
            restrictions or obligations set forth in these terms. We will not be liable to you or
            any third party for any costs or damages as a result of termination. You may terminate
            your use of HPlus Trading Solutions at any time by discontinuing your use or by
            contacting HPlus Consulting.
          </p>
          <p>
            Consequences of Termination: Please refer to our privacy policy to understand how we
            treat information provided to us by you after you have stopped using HPlus Trading
            Solutions.
          </p>
          <p>Obligations Upon Termination: Upon termination of these terms:</p>
          <p>
            Your licenses and rights to HPlus Trading Solutions and associated HPlus Intellectual
            Property are terminated immediately; and
          </p>
          <p>
            You shall permanently delete all personal information received as a result of HPlus
            Trading Solutions and provide HPlus Consulting with certification that you have deleted
            all such personal information and confidential information upon request. The definition
            of personal information depends on the applicable law based on your physical location,
            typically referring to data that identifies an individual or relates to an identifiable
            individual. Please see the privacy policy for additional information about what
            definition of personal information applies to you.
          </p>
          <h3>Privacy and Security</h3>
          <p>
            We respect the privacy of individuals and end users using HPlus Trading Solutions and
            expect you to do the same.
          </p>
          <p>Representations and Warranties: By using HPlus Trading Solutions, you agree to:</p>
          <p>
            Provide End User Support: Offer easily accessible, free-of-charge support for end users,
            addressing any questions or concerns.
          </p>
          <p>
            Provide Reasonable Security: Protect end user personal information from unauthorized
            access or use.
          </p>
          <p>
            Provide Security Incident Notification: Notify HPlus Consulting promptly of any security
            incidents and cooperate in remediation efforts.
          </p>
          <p>
            Provide Privacy Notice and Obtain Consent: Adhere to a privacy policy for your
            application that complies with applicable laws, rules, and regulations.
          </p>
          <p>
            Comply with Applicable Privacy and Security Laws: Adhere to privacy and security laws
            and regulations when accessing and using HPlus Trading Solutions.
          </p>
          <p>
            Data Controller: You are responsible for access, use, processing, disclosure, and
            protection of end user data or personal information.
          </p>
          <p>
            Provision of Personal Information to HPlus Consulting: Do not pass information to HPlus
            Consulting that it could use or recognize as personal information, except when expressly
            requested.
          </p>
          <p>
            Privacy and Security Features: Do not circumvent privacy or security features of HPlus
            Trading Solutions.
          </p>
          <p>
            Requirements for Protection of Personal Information: Protect personal information by not
            selling or disclosing it, encrypting and storing it securely, deleting it upon request
            or termination, and complying with other privacy requirements.
          </p>
          <p>
            Any use of end user personal information other than as expressly permitted by these
            terms is strictly prohibited.
          </p>
          <h3>Compliance with Applicable Laws and Compliance Audit</h3>
          <p>
            Compliance is Your Responsibility: You agree to comply with all applicable laws, rules,
            and regulations related to your use of the HPlus Trading Solutions. If you are an
            entity, you represent that you have adequate policies and procedures in place to ensure
            compliance with applicable law.
          </p>
          <p>
            Surveillance: You will not knowingly assist any government entities, public authorities,
            law enforcement, or other organizations in conducting surveillance or obtaining data
            using your access to HPlus Trading Solutions to avoid serving legal process directly on
            HPlus Consulting.
          </p>
          <p>
            Unlawful or Discriminatory Purposes: You will not process end user personal information
            for unlawful or discriminatory purposes.
          </p>
          <p>
            Compliance Audit Procedures: HPlus Consulting, or a third-party agent subject to
            confidentiality obligations, may inspect and audit any records or activity related to
            your access to or use of HPlus Trading Solutions to verify compliance with these terms.
            You will provide full cooperation and assistance with such audits, including access to
            all relevant information, agreements, and records. The rights and requirements of this
            section will survive for one (1) year following the termination of these terms.
          </p>
          <p>
            Non-Compliant Applications: If you believe someone is violating these terms or misusing
            the HPlus Trading Solutions, please notify HPlus Consulting Support.
          </p>
          <h3>Indemnification; Limitation of Liability</h3>
          <p>
            Indemnification by You: You agree to release, indemnify, and hold HPlus Consulting, its
            affiliates, service providers, and licensors, and each of their respective officers,
            directors, agents, joint venturers, employees, and representatives harmless for the
            following:
          </p>
          <p>
            Any claims arising out of a dispute you have with another user of our services. Any
            claims brought by an end user related to your application or service, including the
            Trading Tools as they are used in your application or service. Any claims arising out of
            or related to your breach of these terms. Any claims arising out of or related to your
            violation of any law, rule or regulation, or the rights of any third party. Your release
            and indemnification of HPlus Consulting is broad and extends to all damages (direct,
            consequential, punitive, or however characterized) and includes our attorneys' fees
            arising out of defending any of the above claims. Your release and indemnification of
            HPlus Consulting also extends to any fines, fees, or penalties imposed by any
            governmental or regulatory authority.
          </p>
          <p>
            HPlus Consulting and its affiliates and licensors will not be liable to you for any
            indirect, incidental, special, consequential, or exemplary damages (including damages
            for slashing penalties, damages related to end users losing access to any products or
            services, missed rewards, loss of profits, revenues, rewards, bond, customers,
            opportunities, goodwill, use, or data), even if a party has been advised of the
            possibility of such damages and regardless of the legal theory (contract, tort, or
            otherwise) on which any such damages may be based. Further, neither HPlus Consulting nor
            any of its affiliates or licensors will be responsible for any compensation,
            reimbursement, or damages arising in connection with: (a) your inability to use the
            HPlus Trading Solutions, including as a result of any (i) termination or suspension of
            these terms or your use of or access to the HPlus Trading Solutions, (ii) HPlus
            Consulting's discontinuation of any or all of the HPlus Trading Solutions, or (iii) any
            unanticipated or unscheduled downtime of all or a portion of the HPlus Trading Solutions
            for any reason; (b) the cost of procurement of substitute goods or services; (c) any
            investments, expenditures, or commitments by you in connection with these terms or your
            use of or access to the HPlus Trading Solutions; or (d) any unauthorized access to,
            alteration of, or the deletion, destruction, damage, loss, or failure to store any of
            your content or other data. In any case, except for payment obligations under Section 5,
            HPlus Consulting's and its affiliates' and licensors' aggregate liability under these
            terms will not exceed the amount you actually paid to HPlus Consulting under these terms
            for the service that gave rise to the claim during the 12 months before the liability
            arose. The limitations in this section apply only to the maximum extent permitted by
            applicable law.
          </p>
          <p>
            The HPlus Trading Solutions are provided "as is" and "as available." No promises or
            warranties should be implied (including the implied warranties of merchantability,
            fitness for a particular purpose, and non-infringement).
          </p>
          <h3>General Provisions</h3>
          <p>
            Governing Law: Florida law governs these terms and the interpretation of this agreement,
            without regard to conflict of law principles.
          </p>
          <p>
            Feedback: If you provide HPlus Consulting with feedback, suggestions, or comments
            regarding the HPlus Trading Solutions, you agree to provide a fully-paid up,
            royalty-free, non-exclusive, worldwide, transferable, sublicensable, perpetual,
            irrevocable right and license under all of your intellectual property rights to HPlus
            Consulting to use, copy, modify, create derivative works, distribute, publicly perform,
            grant sublicenses to, and otherwise exploit in any manner such feedback, suggestions, or
            comments, for any purpose, with no obligation of any kind to you.
          </p>
          <p>
            Independent Contractors: These terms do not result in a joint venture, partnership,
            agency, or fiduciary relationship between you and HPlus Consulting. The parties do not
            intend to create such a relationship under these terms. Nothing in these terms shall in
            any way restrict HPlus Consulting from pursuing any business activities or from entering
            into any agreement with any other person or company.
          </p>
          <p>
            Miscellaneous: Section headings in these terms are for convenience only and shall not
            govern the meaning or interpretation of these terms. In the event that HPlus Consulting
            is acquired by or merged with a third-party entity, we reserve the right to transfer or
            assign the information we have collected from you as part of such merger, acquisition,
            sale, or other change of control. Each corporate affiliate of HPlus Consulting shall be
            a third-party beneficiary to these terms, and such corporate affiliate shall be entitled
            to directly enforce, and rely upon, any provision of these terms. Other than the
            preceding sentence, nothing in these terms shall create any third-party beneficiaries or
            confer any rights in any third parties. You may not assign any rights or licenses
            granted under these terms. We reserve the right to assign our rights without
            restriction, including without limitation to any HPlus Consulting affiliates or to any
            successor in interest of any business associated with the HPlus Services. Any attempted
            transfer or assignment in violation hereof shall be null and void. Subject to the
            foregoing, these terms will bind and inure to the benefit of the parties, their
            successors, and permitted assigns. If any of these terms shall be determined to be
            invalid or unenforceable under any rule, law, or regulation or any governmental agency,
            such provision will be changed and interpreted to accomplish the objectives of the
            provision to the greatest extent possible and the validity or enforceability of any
            other term shall not be affected. The failure or delay by HPlus Consulting to exercise
            or enforce any right or provision of these terms or rights under applicable law shall
            not constitute a waiver of any such provisions or rights. Subject to the laws of each
            jurisdiction, in the event these terms, or any part thereof, is translated into other
            languages, the English language version of these terms shall prevail to the extent there
            is a discrepancy between versions of these terms.
          </p>
        </div>
      </main>
    </>
  )
}
