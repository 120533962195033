import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useOnEscapeKeyDown } from 'utils/use-on-escape-keydown'
import { useOnOutsideClick } from 'utils/use-on-outside-click'
import { calcPopoverPosition, calcTrianglePosition } from './calc-position'
import { AnimatedContent, StyledPopover, Triangle } from './styles'

export type PlacementT = 'top' | 'right' | 'bottom' | 'left'
export type VariantT = 'tooltip' | 'dropdown'

export type PopoverPropsT = {
  variant?: 'tooltip' | 'dropdown'
  placement?: PlacementT
  disabled?: boolean
  maxWidth?: number
  maxHeight?: number
  minHeight?: number
  offsetTop?: number
  offsetLeft?: number
  openOnHover?: boolean
  openOnFocus?: boolean
  closeOnInnerClick?: boolean
  stopClickEventPropagation?: boolean
  fillScreenOnMobile?: boolean
  nonScrollable?: boolean
  className?: string
  renderContent: (args: { close: () => void; setPosition: () => void }) => React.ReactNode
  children: any
}

export const Popover = ({
  variant = 'dropdown',
  placement = 'bottom',
  disabled = false,
  maxWidth = 300,
  maxHeight,
  minHeight,
  offsetTop = 0,
  offsetLeft = 0,
  openOnHover = false,
  openOnFocus = false,
  closeOnInnerClick = false,
  stopClickEventPropagation = false,
  fillScreenOnMobile = false,
  nonScrollable = false,
  className,
  renderContent,
  children,
}: PopoverPropsT) => {
  const [isOpen, setIsOpen] = useState(false)

  const anchorRef = useRef<HTMLDivElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)
  const triangleRef = useRef<HTMLDivElement>(null)
  const lockRef = useRef(false) // Needed for cases where hover tooltip appears on top of the anchor

  const closeDropdown = () => {
    // Prevent close if a child modal/dropdown/popover is open
    if (popoverRef.current?.nextSibling) return

    // Set timeout to make sure parent modals/dropdowns/popovers have time
    // to make their nextSibling checks before this one is closed
    setTimeout(() => {
      setIsOpen(false)
    }, 0)
  }

  useOnOutsideClick([popoverRef, anchorRef], isOpen, closeDropdown)
  useOnEscapeKeyDown(isOpen, closeDropdown)

  const setPosition = useCallback(() => {
    if (!popoverRef.current || !anchorRef.current) return

    popoverRef.current.style.height = 'auto'
    popoverRef.current.style.maxHeight = 'none'

    const popover = calcPopoverPosition(
      popoverRef as { current: HTMLDivElement },
      anchorRef as { current: HTMLDivElement },
      placement,
      maxHeight,
      minHeight
    )
    popoverRef.current.style.top = `${popover.top + offsetTop}px`
    popoverRef.current.style.left = `${popover.left + offsetLeft}px`
    popoverRef.current.style.height = `${popover.height}px`
    popoverRef.current.style.maxHeight = `${popover.height}px`

    if (triangleRef.current) {
      const triangle = calcTrianglePosition(
        popoverRef as { current: HTMLDivElement },
        anchorRef as { current: HTMLDivElement },
        placement
      )
      if ('top' in triangle) triangleRef.current.style.top = `${triangle.top}px`
      if ('left' in triangle) triangleRef.current.style.left = `${triangle.left}px`
    }
  }, [maxHeight, minHeight, offsetLeft, offsetTop, placement])

  useLayoutEffect(setPosition)

  useEffect(() => {
    window.addEventListener('scroll', setPosition)
    window.addEventListener('resize', setPosition)
    return () => {
      window.removeEventListener('scroll', setPosition)
      window.removeEventListener('resize', setPosition)
    }
  }, [setPosition])

  if (disabled) return <>{children}</>

  return (
    <>
      {React.cloneElement(children, {
        ...children.props,
        ref: anchorRef,
        onClick: (e: React.MouseEvent) => {
          if (stopClickEventPropagation) e.stopPropagation()
          if (children.props.onClick) children.props.onClick(e)
          if (!openOnHover && !openOnFocus) setIsOpen(!isOpen)
        },
        onMouseEnter: () => {
          children.props.onMouseEnter?.()
          if (openOnHover) setIsOpen(true)
        },
        onMouseLeave: () => {
          children.props.onMouseLeave?.()
          if (openOnHover) {
            setTimeout(() => {
              if (!lockRef.current) setIsOpen(false)
            }, 0)
          }
        },
        onFocus: () => {
          if (openOnFocus) setIsOpen(true)
        },
      })}

      {isOpen &&
        ReactDOM.createPortal(
          <StyledPopover
            placement={placement}
            variant={variant}
            maxWidth={maxWidth}
            fillScreenOnMobile={fillScreenOnMobile}
            ref={popoverRef}
            onMouseEnter={() => {
              if (openOnHover) {
                lockRef.current = true
              }
            }}
            onMouseLeave={() => {
              if (openOnHover) {
                lockRef.current = false
                setIsOpen(false)
              }
            }}
            onClick={(e: React.MouseEvent) => {
              if (stopClickEventPropagation) e.stopPropagation()
              if (closeOnInnerClick) closeDropdown()
            }}
          >
            <AnimatedContent
              variant={variant}
              placement={placement}
              nonScrollable={nonScrollable}
              className={className}
            >
              {renderContent({ close: closeDropdown, setPosition })}
              {variant === 'tooltip' && <Triangle ref={triangleRef} placement={placement} />}
            </AnimatedContent>
          </StyledPopover>,
          document.getElementById('hp-popover')!
        )}
    </>
  )
}

export const Tooltip = observer((props: PopoverPropsT) => {
  return <Popover variant="tooltip" openOnHover placement="top" {...props} />
})
