export const Gauge = ({ score, width }: { score: number; width: number }) => {
  const strokeWidth = width / 7
  const radius = width / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (circumference * score) / 10

  return (
    <svg width={width} height={width}>
      <circle
        cx={width / 2}
        cy={width / 2}
        r={radius}
        fill="none"
        stroke="#23272f"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={width / 2}
        cy={width / 2}
        r={radius}
        fill="none"
        stroke="#9E70E8"
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={offset}
        transform={`rotate(-90 ${width / 2} ${width / 2})`}
      />
      <text
        x={width / 2}
        y={width / 2 - width / 10}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={width / 4}
        fontWeight="bold"
        fill="#9E70E8"
      >
        {score}
        <tspan
          x={width / 2}
          y={width / 2 + width / 14}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={width / 14}
          fill="#9E70E8"
        >
          Market
        </tspan>
        <tspan
          x={width / 2}
          y={width / 2 + width / 6}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={width / 14}
          fill="#9E70E8"
        >
          Sentiment
        </tspan>
      </text>
    </svg>
  )
}
