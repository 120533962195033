import Globe from 'components/globe'
import Navbar from 'components/navbar'
import { Helmet } from 'react-helmet'
import style from 'styles/pages/about.module.scss'

export default function About() {
  return (
    <>
      <Helmet>
        <title>About | H+ Trading Solutions</title>
        <meta name="description" content="Transforming Data into Trading Mastery" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Navbar />

      <main>
        <div className={style.globeContainer}>
          <h1 className={style.heading}>Cutting-edge fintech</h1>
          <Globe />
        </div>

        <div className={style.content}>
          <p className={style.largeText} style={{ margin: '0 0 24px' }}>
            HPlus Consulting is a cutting-edge financial technology company that specializes in
            providing advanced solutions for trading and market data analysis across various asset
            classes.
          </p>
          <p className={style.paragraph}>
            Leveraging state-of-the-art tools and sophisticated algorithms, HPlus Trading Solutions
            deliver unparalleled capabilities in areas such as crypto wallets, matching engines,
            trade execution, analytics, market data platforms, NFT valuation, token launches,
            trading surveillance, dynamic market risk management, and high-frequency market making.
            With a strong focus on innovation, HPlus Trading Solutions empower financial
            institutions and individual traders to optimize their strategies, manage risk, and
            capitalize on emerging opportunities in today's rapidly evolving markets.
          </p>
          <p className={style.largeText} style={{ margin: '48px 0 24px' }}>
            HPlus Consulting's offerings are designed to empower users to navigate the complex
            financial landscape while maintaining full control over their own trading and investment
            activities.
          </p>
          <p className={style.paragraph}>
            We are a premier technology, data, analytics, and algorithm provider committed to
            delivering state-of-the-art tools and insights for the financial industry, and it is
            important to note that HPlus Consulting does not provide any financial services, trade
            its own capital, or offer investment advisory services. As a technology-focused company,
            HPlus Consulting serves solely as a provider of powerful tools and comprehensive data
            solutions that enable clients to make informed decisions and optimize their strategies.
            Clients are responsible for their own decisions, and HPlus Consulting assumes no
            liability for any losses incurred as a result of using its tools, data, or algorithms.
          </p>
        </div>

        <h2 className={style.subheading} style={{ padding: '96px 48px' }}>
          We are a team contributing experience from
        </h2>

        <div className={style.companyGrid}>
          <img src="companies/logo1.svg" />
          <img src="companies/logo2.svg" />
          <img src="companies/logo3.svg" style={{ width: '50%' }} />
          <img src="companies/logo4.svg" />
          <img src="companies/logo5.svg" />
          <img src="companies/logo6.svg" />
          <img src="companies/logo7.svg" />
          <img src="companies/logo8.svg" />
          <img src="companies/logo9.svg" />
          <img src="companies/logo10.svg" />
          <img src="companies/logo11.svg" />
          <img src="companies/logo12.svg" />
          <img src="companies/logo13.svg" />
          <img src="companies/logo14.svg" style={{ width: '60%' }} />
          <img src="companies/logo15.svg" />
          <img src="companies/logo16.svg" style={{ width: '80%' }} />
          <img src="companies/logo17.svg" />
          <img src="companies/logo18.svg" />
        </div>
      </main>
    </>
  )
}
