import { ClientJS } from 'clientjs'
import { createBrowserStorage } from 'utils/browser-storage'
import { getCookie, setCookie } from 'utils/cookies'

const storage = createBrowserStorage<{ createdAt: number }>('clid')

const setClientIdCookie = (id: string) => setCookie('client_id', id, 365)
const getClientIdCookie = () => getCookie('client_id')

const isFingerprintCookieStale = () =>
  !getClientIdCookie() || Date.now() - (storage.get().createdAt || 0) > 1000 * 60 * 60 * 48

export const generateFingerprintIfStale = () => {
  if (isFingerprintCookieStale()) {
    generateFingerprint()
  }
}
const generateFingerprint = async () => {
  const client = new ClientJS()
  const result = client.getFingerprint()
  setClientIdCookie(result.toString())
  storage.merge({ createdAt: Date.now() })
}
