import { publicSocketOn } from 'api-socket/public-socket'
import { api } from 'api/adapter'
import { orderBy } from 'lodash'
import { observable, runInAction } from 'mobx'

type Tick = {
  px: number
  sym: string
  ts: string
}

export const symbols = [
  'BTC',
  'ETH',
  'DOG',
  'LTC',
  'BNB',
  'XRP',
  'LIN',
  'SOL',
  'ADA',
  'TRX',
  'DOT',
  'MAT',
  'APE',
  'APT',
  'FTM',
  'ZEC',
]

const tickers = observable<{ [symbol: string]: Tick[] }>({})

export const getTickers = (symbol: string) => tickers[symbol] || []

const fetchHistoricTickers = async () => {
  try {
    const response = await api.get('/api/tickers')
    runInAction(() => {
      symbols.forEach((symbol) => {
        tickers[symbol] = orderBy(
          getLast60SecondsPrices([
            ...(tickers[symbol] || []),
            ...response.filter((t: Tick) => t.sym === symbol),
          ]),
          (t) => t.ts,
          ['asc']
        )
      })
    })
  } catch (error) {
    console.log(error)
  }
}
fetchHistoricTickers()

publicSocketOn('tickers', async ({ data }: { data: Tick[] }) => {
  runInAction(() => {
    data.forEach((tick) => {
      tickers[tick.sym] = getLast60SecondsPrices([...(tickers[tick.sym] || []), tick])
    })
  })
})

export const getInstrumentPriceDirection = (symbol: string) => {
  const ticks = getTickers(symbol)
  const [secondToLastTick, lastTick] = ticks.slice(-2)
  if (!lastTick || !secondToLastTick) return 'up'
  return lastTick.px > secondToLastTick.px ? 'up' : 'down'
}

const getLast60SecondsPrices = (prices: Tick[]) => {
  const now = new Date()
  const last60Seconds = new Date(now.getTime() - 60000)

  const timestamps = []
  for (let i = 0; i < 60; i++) {
    const timestamp = new Date(now.getTime() - i * 1000).toISOString()
    timestamps.unshift(removeMs(timestamp))
  }
  const groupedPrices: any = {}
  prices.forEach((price) => {
    if (price.ts >= last60Seconds.toISOString() && price.ts <= now.toISOString()) {
      groupedPrices[removeMs(price.ts)] = price
    }
  })

  const last60SecondsPrices: any = []
  timestamps.forEach((timestamp) => {
    if (groupedPrices[timestamp]) {
      last60SecondsPrices.push(groupedPrices[timestamp])
    }
  })

  return last60SecondsPrices
}

const removeMs = (date: string) => {
  const dateParts = date.split('.')
  dateParts.pop()
  return dateParts[0]
}
