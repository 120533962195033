import { getRecaptchaV2 } from 'utils/recaptcha'
import { uniqueId } from 'lodash'
import { useEffect, useRef } from 'react'

export const Recaptcha = ({
  capRef = {},
  onVerify,
  onAbort = () => {},
}: {
  capRef: { current?: { execute: () => Promise<void> } }
  onVerify: (token: string) => Promise<void>
  onAbort?: () => void
}) => {
  const wasExecuted = useRef(false)
  const executeRequested = useRef(false)
  const divId = useRef(uniqueId('recaptcha'))
  const widgetId = useRef<number | null>(null)

  capRef.current = {
    execute: async () => {
      if (widgetId.current === null) {
        executeRequested.current = true
        return
      }
      try {
        const recaptchaV2 = await getRecaptchaV2()

        if (wasExecuted.current) {
          recaptchaV2.reset(widgetId.current)
        }
        await recaptchaV2.execute(widgetId.current)
        wasExecuted.current = true
      } catch (error) {
        console.log(error)
      }
    },
  }

  useEffect(() => {
    ;(async () => {
      try {
        const recaptchaV2 = await getRecaptchaV2()

        if (widgetId.current !== null || divId.current === null || !capRef.current) return

        widgetId.current = recaptchaV2.render(divId.current, {
          sitekey: '6Leknr4lAAAAAD6-CLC_JD7ifblENI25aJ55dfDl',
          isolated: true,
          size: 'invisible',
          callback: onVerify,
          'error-callback': onAbort,
          'expired-callback': onAbort,
        })
        if (executeRequested.current) {
          executeRequested.current = false
          capRef.current.execute()
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [capRef, onAbort, onVerify])

  useEffect(() => {
    let wasPuzzleModalOpen = false

    const interval = setInterval(() => {
      const iframes = Array.from(
        document.querySelectorAll("iframe[src*='google.com/recaptcha/api2/bframe']"),
      )
      const isPuzzleModalOpen = iframes.some((el) =>
        el.parentElement?.parentElement?.getAttribute('style')?.includes('visibility: visible;'),
      )
      if (wasPuzzleModalOpen && !isPuzzleModalOpen) onAbort()
      wasPuzzleModalOpen = isPuzzleModalOpen
    }, 200)

    return () => clearInterval(interval)
  }, [onAbort])

  return <div id={divId.current} />
}
