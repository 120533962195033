import Navbar from 'components/navbar'
import { Helmet } from 'react-helmet'
import style from 'styles/pages/tos-privacy.module.scss'

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | H+ Trading Solutions</title>
        <meta name="description" content="Transforming Data into Trading Mastery" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Navbar />

      <main>
        <h1 className={style.heading}>Privacy Policy</h1>

        <div className={style.content}>
          <p>
            This policy describes how we treat personal information. It applies to personal
            information we collect on the sites and apps where it is found in connection with HPlus
            Trading Solutions.
          </p>
          <p>We collect information from and about you.</p>
          <p>
            We collect contact information. As part of the registration process on
            hplusconsulting.com, we collect your name and email address. We may also collect your
            address and phone number.
          </p>
          <p>
            We collect identity verification information. We may collect photocopies of your
            government-issued ID. We may also collect copies of your utility bills.
          </p>
          <p>
            We collect information from third parties. We may have connections from our website to
            social media platforms. We may collect name and gender from those platforms. We may also
            collect information from third parties. This may include information to verify your
            identity.
          </p>
          <p>
            We collect usage and device information. We may collect information about the browser or
            device you are using. We may also collect technical information about how you connected
            to us. This might include your operating system or phone model. We might also collect
            ISP or mobile carrier information. We also collect IP addresses.
          </p>
          <p>We collect information in different ways.</p>
          <p>
            We collect information directly from you. We collect information when you register on
            our site or fill out a profile. We also collect information when you place an order. We
            also collect information if you subscribe to a newsletter or respond to a survey.
          </p>
          <p>
            We collect information from third parties. We may collect information from your company.
            We may also collect information from those who validate identity.
          </p>
          <p>
            We collect information from you passively. We use third-party tools to collect
            information from you. We do this on our websites and in emails that we send to you. To
            learn more about the tools we use and your choices, read below.
          </p>
          <p>We combine information.</p>
          <p>
            We may combine information that we get from a third party with information we already
            have. We may also combine information from your profile with information you submit in
            surveys.
          </p>
          <p>We use information as disclosed and described here.</p>
          <p>
            We use information to respond to your questions and requests. We use information to
            respond to your questions and requests. We use your information to respond to questions
            about our solutions or resources or your relationship with us.
          </p>
          <p>
            We use information for surveys. We use information to tell you about new products. We
            use information we have about you to tailor these invitations to you. We also may use
            your information to send you product surveys.
          </p>
          <p>
            We use information to improve products and services. We use your information to improve
            our sites and services. We use your information to customize your experience with us.
            Information you provide helps us respond to your customer service requests and support
            your needs more efficiently.
          </p>
          <p>
            We use information for security purposes. We use your information to verify your
            identity. This includes using copies of your ID to verify your identity. We also use
            your information for anti-money-laundering purposes and to protect our company and our
            customers. We also use the information to protect our websites.
          </p>
          <p>
            We use information for marketing purposes. We provide you with information about new
            products and special offers. We do this when legally permitted.
          </p>
          <p>
            We use information to communicate with you about our relationship. We may contact you
            about this Privacy Policy or our Terms of Use. We may also contact you about your
            account and account settings. We may also contact you regarding updates to the features
            on our sites.
          </p>
          <p>
            We use information to process orders and payments, or assist in value transfers. We use
            information you provide about yourself when placing an order to provide service to that
            order.
          </p>
          <p>We use information as otherwise permitted by law or as we may notify you.</p>
          <p>We may share information with third parties.</p>
          <p>
            We will share information with third parties who perform services on our behalf. We may
            share your personal information with trusted third parties for data processing and
            verification purposes. These include companies who serve as our vendors and help us
            ultimately deliver products or services to you.
          </p>
          <p>
            We will share information if we think we have to in order to comply with the law or to
            protect you or ourselves. We will share information to respond to a court order or
            subpoena. We may share information if a government agency or investigatory body requests
            it. This includes U.S. law enforcement or regulatory authorities.
          </p>
          <p>We may share information if we are investigating potential fraud.</p>
          <p>
            We may share information with a successor to all or part of our business, as permitted
            by law. If part of our business is sold, we may include customer information as part of
            that transaction. Where legally required, we will give you prior notice and if you have
            the legal right to do so, allow you to object.
          </p>
          <p>
            We will share information with our business partners and clients. We may share billing
            information. We may also share usage statistics.
          </p>
          <p>We may share information for other reasons we may describe to you.</p>
          <p>You have certain choices about how we use your information.</p>
          <p>
            You can opt out of receiving certain emails from us. To opt out of marketing emails,
            please contact us. You can also follow the instructions in any such message you get from
            us. If you opt out of marketing emails, you will continue to receive messages from us
            about your relationship with us where permitted by law. You can deactivate your account
            from your user profile on our site.
          </p>
          <p>
            You can access and update your information. To update or correct information, you can
            log into your account. You can also contact us.
          </p>
          <p>
            You may also have certain rights regarding your personal information under applicable
            data protection laws. If applicable, you may be able to request that we delete or
            transfer your information to a third party of your choice. You may also be able to
            restrict the processing or your personal information or object to the basis of our
            processing of your personal information.
          </p>
          <p>This Site is not intended for children.</p>
          <p>
            Our Site is meant for adults. We do not knowingly collect personally identifiable
            information from minors (children under 18). If you are a parent or legal guardian and
            think your child has given us information, you can contact us via email or at the
            address listed at the end of this Privacy Policy. Please mark your inquiries “Child PII
            Request.” Parents, you can learn more about how to protect your children’s privacy
            online here.
          </p>
          <p>We use standard security measures.</p>
          <p>
            We use appropriate and reasonable security measures as required by relevant laws. The
            Internet is not 100% secure. We cannot promise that your use of our Site or App will be
            completely safe. We encourage you to use caution when using the Internet.
          </p>
          <p>
            We keep personal information for as long as necessary or relevant for the practices
            described here. We keep information as otherwise required by law. If you cancel your
            account, we may keep archived copies of your information as permitted by law.
          </p>
          <p>We may store information both inside and outside of the United States.</p>
          <p>
            Information we collect may be stored in the United States. If you live outside of the
            United States, you understand and agree that we may transfer your information in the
            United States. The United States may not afford the same level of protection as the laws
            in your country.
          </p>
          <p>We may link to third party sites or services we do not control.</p>
          <p>
            We may link to third-party sites and apps. Our site may also include third party content
            that collects information. We do not control these third parties. This Policy does not
            apply to the privacy practices of that website or app. Please read the privacy policy of
            other websites or apps carefully. We are not responsible for the practices of these
            third-party sites or apps.
          </p>
          <p>Feel free to contact us if you have more questions.</p>
          <p>We may update this Privacy Policy.</p>
        </div>
      </main>
    </>
  )
}
