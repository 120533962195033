type HandlerT = (...args: any) => void

export class EventBus {
  _listeners: {
    [eventName: string]: HandlerT[]
  }

  constructor() {
    this._listeners = {}
  }

  emit(eventName: string, ...data: any) {
    this._getListeners(eventName).forEach((listener) => listener(...data))
  }

  on(eventName: string, handler: HandlerT) {
    this._listeners[eventName] = [...this._getListeners(eventName), handler]
  }

  off(eventName: string, handler: HandlerT) {
    this._listeners[eventName] = this._getListeners(eventName).filter((l) => l !== handler)
  }

  _getListeners(eventName: string) {
    return this._listeners[eventName] || []
  }
}
