type KeepMaxPrecisionT = `keep-max:${number}`

type FormatNumberOptionsT = {
  dashIfZero?: boolean
  removeTrailingZeros?: boolean
  precision?: number | 'auto' | 'short' | 'keep' | KeepMaxPrecisionT
}

export const formatNumber = (value: number, options: FormatNumberOptionsT = {}) => {
  if (options.dashIfZero && value === 0) return '-'

  let precision = 2

  if (typeof options.precision === 'number') {
    precision = options.precision
  }
  if (options.precision === 'auto') {
    precision = calcAutoPrecision(value)
  }
  if (options.precision === 'short') {
    precision = calcShortPrecision(value)
  }
  if (options.precision === 'keep') {
    precision = `${value}`.split('.')[1]?.length || 0
  }
  if (options.precision?.toString().includes('keep-max:')) {
    precision = calcKeepMaxPrecision(value, options.precision as KeepMaxPrecisionT)
  }

  let result = value.toLocaleString('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })

  return options.removeTrailingZeros || options.precision?.toString().includes('keep-max:')
    ? removeTrailingDecimalZeros(result)
    : result
}

export const formatCurrency = (value: number, options: FormatNumberOptionsT = {}) => {
  const formatted = formatNumber(value, options)
  if (formatted === '-') return '-'
  return formatted.includes('-') ? `-$${formatted.replace('-', '')}` : `$${formatted}`
}

export const formatPercentage = (value: number, options: FormatNumberOptionsT = {}) =>
  `${formatNumber(value, options)}%`

export const calcAutoPrecision = (value: number) => {
  const abs = Math.abs(value)
  if (abs === 0) return 2
  if (abs >= 100_000) return 0
  if (abs >= 10_000) return 1
  if (abs >= 1_000) return 2
  if (abs >= 100) return 3
  if (abs >= 10) return 4
  if (abs >= 1) return 5
  if (abs >= 0.1) return 6
  if (abs >= 0.01) return 7
  if (abs >= 0.001) return 8
  return 9
}

const calcShortPrecision = (value: number) => {
  const precision = `${value}`.split('.')[1]?.length || 0
  const abs = Math.abs(value)
  if (abs >= 10_000 && precision > 0) return 0
  if (abs >= 1_000 && precision > 1) return 1
  if (abs >= 100 && precision > 2) return 2
  if (abs >= 10 && precision > 3) return 3
  if (abs >= 1 && precision > 4) return 4
  if (precision > 5) return 5
  return precision
}

const calcKeepMaxPrecision = (value: number, precisionOption: KeepMaxPrecisionT) => {
  const decimalsString = `${value}`.split('.')[1] || ''
  const valuePrecision = decimalsString.length
  const maxPrecision = Number(precisionOption.split(':')[1])
  return Math.min(valuePrecision, maxPrecision)
}

const removeTrailingDecimalZeros = (value: string) => {
  if (!value.includes('.')) return value
  return value.replace(/0+$/, '').replace(/\.$/, '')
}

export const timeSince = (date: number): string => {
  const secondsPast = (Date.now() - date) / 1000

  if (secondsPast < 60) {
    return Math.round(secondsPast) + 's'
  }
  if (secondsPast < 60 * 60) {
    return Math.round(secondsPast / 60) + 'm'
  }
  if (secondsPast < 60 * 60 * 24) {
    return Math.round(secondsPast / (60 * 60)) + 'h'
  }
  return Math.round(secondsPast / (60 * 60 * 24)) + 'd'
}
