import { Socket } from './adapter/web-socket'

/* const wsUrl = 'wss://hplusconsulting.com/api/live' */

let wsUrl = window.location.origin
  .replace('http:', 'ws:')
  .replace('https:', 'wss:')
  .replace('3000', '3001')
  .replace(/\/$/, '')

const socket: Socket = new Socket(wsUrl + '/api/live')

socket.connect()

export const publicSocketOn = (eventName: string, callback: (data: any) => void) =>
  socket?.subscribe(eventName, callback)
