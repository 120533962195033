import { api } from 'api/adapter'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { formatCurrency, timeSince } from 'utils/format'
import style from './styles.module.scss'

export type NftSale = {
  description: string
  image: string
  name: string
  price: number
  ts: string
}

export const Nfts = observer(() => {
  const [nfts, setNfts] = useState<NftSale[]>([])

  const fetchNfts = async () => {
    try {
      const response = await api.get('/api/nfts')
      setNfts(response)
    } catch {}
  }

  useEffect(() => {
    fetchNfts()
  }, [])

  return (
    <ImageCarousel
      key={JSON.stringify(nfts)}
      items={nfts.map((nft, i) => (
        <NftItem key={i} nft={nft} />
      ))}
      itemWidth={224}
      onComplete={fetchNfts}
    />
  )
})

const NftItem = observer(({ nft }: { nft: NftSale }) => {
  return (
    <div className={style.nft}>
      <div className={style.imageWrapper}>
        <img src={nft.image} />
      </div>
      <div className={style.name}>{nft.name}</div>
      <div className={style.time}>Sold {timeSince(Number(new Date(nft.ts + 'Z')))} ago</div>
      <div className={style.price}>{formatCurrency(nft.price)}</div>
    </div>
  )
})

const ImageCarousel = ({
  items,
  itemWidth,
  onComplete,
}: {
  items: React.ReactNode[]
  itemWidth: number
  onComplete?: () => void
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef<any>(null)

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === items.length - 1) {
          onComplete?.()
          return 0
        }
        return prevIndex + 1
      })
    }, 2000)

    return () => {
      clearInterval(scrollInterval)
    }
  }, [items, onComplete])

  useEffect(() => {
    if (carouselRef.current) {
      if (currentIndex === 0) {
        carouselRef.current.style.transition = 'none'
      } else {
        carouselRef.current.style.transition = 'transform 0.5s ease'
      }
      carouselRef.current.style.transform = `translateX(-${currentIndex * (itemWidth + 12)}px)`
    }
  }, [currentIndex, itemWidth])

  return (
    <div className={style.carousel}>
      <div ref={carouselRef} className={style.carouselInner}>
        {items.map((item, index) => (
          <div
            key={index}
            className={style.carouselItem}
            style={{ width: itemWidth, marginRight: index === items.length - 1 ? 0 : 12 }}
          >
            {item}
          </div>
        ))}
        <div className={style.carouselItem} style={{ width: itemWidth, marginLeft: 12 }}>
          {items[0]}
        </div>
      </div>
    </div>
  )
}
