import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useOnEscapeKeyDown } from 'utils/use-on-escape-keydown'
import { useOnOutsideClick } from 'utils/use-on-outside-click'
import style from './styles.module.scss'

type ModalT = {
  className?: string
  maxWidth?: number
  preventClose?: boolean
  onClose?: () => void
  renderContent: (helpers: {
    close: () => void
    setMaxWidth: (maxWidth: number) => void
  }) => React.ReactNode
}

export const Modal = ({
  className,
  maxWidth: propsMaxWidth = 600,
  preventClose,
  onClose: tellParentToClose = () => {},
  renderContent,
}: ModalT) => {
  const [maxWidth, setMaxWidth] = useState(propsMaxWidth)
  const scrollOverlayRef = useRef<HTMLDivElement>(null)
  const clickableOverlayRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)

  const closeModal = useCallback(() => {
    if (preventClose) return

    // Prevent close if a child modal/dropdown/popover is open
    if (scrollOverlayRef.current?.nextSibling) return

    // Set timeout to make sure parent modals/dropdowns/popovers have time
    // to make their nextSibling checks before this one is closed
    setTimeout(tellParentToClose, 0)
  }, [preventClose, tellParentToClose])

  useOnOutsideClick(modalRef, true, closeModal, clickableOverlayRef)
  useOnEscapeKeyDown(true, closeModal)

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])

  return ReactDOM.createPortal(
    <div className={style.scrollOverlay} ref={scrollOverlayRef}>
      <div className={style.clickableOverlay} ref={clickableOverlayRef}>
        <div className={`${style.modal} ${className}`} style={{ maxWidth }} ref={modalRef}>
          {renderContent({ close: closeModal, setMaxWidth })}
        </div>
      </div>
    </div>,
    document.getElementById('hp-popover')!
  )
}

export const AutoModal = ({
  renderHandle,
  onClose,
  ...modalProps
}: ModalT & { renderHandle?: () => React.ReactElement }) => {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => {
    onClose?.()
    setIsOpen(false)
  }, [onClose])

  return (
    <>
      {renderHandle &&
        React.cloneElement(renderHandle(), {
          onClick: () => setIsOpen(true),
        })}

      {isOpen && <Modal onClose={closeModal} {...modalProps} />}
    </>
  )
}
