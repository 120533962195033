import { api } from 'api/adapter'
import Plotly from 'plotly.js-dist-min'
import { useEffect, useState } from 'react'
import { useIsScreenMaxWidth } from 'utils/media-query'

type Data = {
  ts: string[]
  strikes: number[]
  ivs: number[][]
}

export const SurfaceChart = () => {
  const [data, setData] = useState<Data | null>(null)
  const isMax1023 = useIsScreenMaxWidth(1023)

  useEffect(() => {
    ;(async () => {
      try {
        const response: Data = await api.get('/api/iv/btc')
        setData({
          ...response,
          ivs: response.ivs[0].map((_, i) => response.ivs.map((row) => row[i])),
        })
      } catch {}
    })()
  }, [])

  useEffect(() => {
    if (!data) return

    Plotly.newPlot(
      'hpc-surface-chart',
      [
        {
          x: data.ts,
          y: data.strikes,
          z: data.ivs.map((iv) => iv.map((v) => v != null ? v * 100 : null)),
          type: 'surface',
          opacity: 0.8,
          showscale: false,
        },
      ],
      {
        title: {
          text: 'BTC Volatility Surface',
          y: 0.95,
          font: {
            color: '#fff',
          },
        },
        paper_bgcolor: '#101216',
        scene: {
          camera: {
            center: {
              x: 0,
              y: 0,
              z: -0.08,
            },
            eye: {
              x: 1.4,
              y: 1.4,
              z: 1.4,
            },
          },
          xaxis: {
            title: 'Expiry Date',
            titlefont: { size: 12 },
            color: '#fff',
            gridcolor: '#525C70',
          },
          yaxis: {
            title: 'Strike Price',
            titlefont: { size: 12 },
            color: '#fff',
            gridcolor: '#525C70',
          },
          zaxis: {
            title: 'Implied Volatility',
            titlefont: { size: 12 },
            showticksuffix: 'all',
            ticksuffix: '%',
            color: '#fff',
            gridcolor: '#525C70',
          },
        },
        mapbox: {
          zoom: 100,
        },
        autosize: true,
        height: isMax1023 ? 300 : 500,
        margin: {
          l: 16,
          r: 0,
          b: 16,
          t: 16,
        },
      },
    )
  }, [data, isMax1023])

  return <div id="hpc-surface-chart" />
}
