// @ts-nocheck

// import { Color, Scene, WebGLRenderer, PerspectiveCamera, TextureLoader, Texture, SphereGeometry, MeshBasicMaterial, Mesh, Geometry, PointsMaterial, Vector3, Points, Group, QuadraticBezierCurve3 } from 'three';
import { MeshLine, MeshLineMaterial } from './meshLine.js'

import globePoints from './points'
import threeOrbitControls from './ThreeOrbitControls.js'

// https://codepen.io/anon/pen/EoozvR

let canvas: any
let scene: any
let renderer: any
let container: any
let list: any
let globe: any
let elements: any
let groups: any
let props: any
let camera: any
let animations: any
let isHidden: boolean
let globeSize: any
let resizeCheckerInterval: any
let canvasResizeBehaviourTimeout: any
let canvasResizeBehaviour: any
let onOrientationchange: any
let isActive: any

function init() {
  isActive = true

  // Object for country HTML elements and variables
  elements = {}

  // Map properties for creation and rendering
  groups = {
    main: null, // A group containing everything
    globe: null, // A group containing the globe sphere (and globe dots)
    globeDots: null, // A group containing the globe dots
    lines: null, // A group containing the lines between each country
    lineDots: null, // A group containing the line dots
  }

  // Map properties for creation and rendering
  props = {
    mapSize: {
      // Size of the map from the intial source image (on which the dots are positioned on)
      width: 2048 / 2,
      height: 1024 / 2,
    },
    globeRadius: 200, // Radius of the globe (used for many calculations)
    dotsAmount: 20, // Amount of dots to generate and animate randomly across the lines
    startingCountry: 'madrid', // The key of the country to rotate the camera to during the introduction animation (and which country to start the cycle at)
    colours: {
      // Cache the colours
      globeDots: '#9E70E8', // No need to use the Three constructor as this value is used for the HTML canvas drawing 'fillStyle' property
      lines: new THREE.Color('#9FE870'),
      lineDots: new THREE.Color('#9FE870'),
    },
    alphas: {
      // Transparent values of materials
      globe: 0.4,
      lines: 0.5,
    },
  }

  // Angles used for animating the camera
  camera = {
    object: null, // Three object of the camera
    controls: null, // Three object of the orbital controls
    angles: {
      // Object of the camera angles for animating
      current: {
        azimuthal: null,
        polar: null,
      },
      target: {
        azimuthal: null,
        polar: null,
      },
    },
  }

  // Booleans and values for animations
  animations = {
    finishedIntro: false, // Boolean of when the intro animations have finished
    dots: {
      current: 0, // Animation frames of the globe dots introduction animation
      total: 170, // Total frames (duration) of the globe dots introduction animation,
      points: [], // Array to clone the globe dots coordinates to
    },
    globe: {
      current: 0, // Animation frames of the globe introduction animation
      total: 80, // Total frames (duration) of the globe introduction animation,
    },
    countries: {
      active: false, // Boolean if the country elements have been added and made active
      animating: false, // Boolean if the countries are currently being animated
      current: 0, // Animation frames of country elements introduction animation
      total: 120, // Total frames (duration) of the country elements introduction animation
      selected: null, // Three group object of the currently selected country
      index: null, // Index of the country in the data array
      timeout: null, // Timeout object for cycling to the next country
      initialDuration: 100, // Initial timeout duration before starting the country cycle
      duration: 2000, // Timeout duration between cycling to the next country
    },
  }

  // Boolean to enable or disable rendering when window is in or out of focus
  isHidden = false
}

export function setVisibility(hidden: boolean) {
  isHidden = hidden
}

export function loadScene(jsGlobe: any, jsCanvas: any, jsList: any) {
  // prevent SSR erroring
  if (typeof THREE === 'undefined') return

  jsGlobe.className = 'globe'

  canvas = jsCanvas
  container = jsGlobe
  list = jsList

  init()

  scene = new THREE.Scene()
  renderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    alpha: true,
    shadowMapEnabled: false,
  })

  renderer.setSize(canvas.clientWidth, canvas.clientHeight)
  renderer.setPixelRatio(1)
  renderer.setClearColor(0x000000, 0)

  // Main group that contains everything
  groups.main = new THREE.Group()
  groups.main.name = 'Main'

  // Group that contains lines for each country
  groups.lines = new THREE.Group()
  groups.lines.name = 'Lines'
  groups.main.add(groups.lines)

  // Group that contains dynamically created dots
  groups.lineDots = new THREE.Group()
  groups.lineDots.name = 'Dots'
  groups.main.add(groups.lineDots)

  // Add the main group to the scene
  scene.add(groups.main)

  // Render camera and add orbital controls
  addCamera()
  addControls()

  // Render objects
  addGlobe()

  if (Object.keys(globePoints.countries).length > 0) {
    addLines()
    createListElements()
  }

  // Start the requestAnimationFrame loop
  render()
  animate()

  if (!resizeCheckerInterval) {
    resizeCheckerInterval = setInterval(function () {
      if (globeSize.w !== container.offsetWidth || globeSize.h !== container.offsetHeight) {
        canvasResizeBehaviour()
      }
    }, 500)
  }

  canvasResizeBehaviour = function () {
    /*
    container.width = window.innerWidth
    container.height = window.innerHeight
    container.style.width = window.innerWidth + 'px'
    container.style.height = window.innerHeight + 'px'
    */

    const w = container.offsetWidth
    const h = container.offsetHeight

    camera.object.aspect = w / h
    camera.object.updateProjectionMatrix()
    renderer.setSize(w, h)

    globeSize = { w, h }
  }
  onOrientationchange = function () {
    canvasResizeBehaviourTimeout = setTimeout(canvasResizeBehaviour, 0)
  }

  window.addEventListener('resize', canvasResizeBehaviour)
  window.addEventListener('orientationchange', onOrientationchange)

  canvasResizeBehaviour()
}

export function unloadScene() {
  isActive = false

  window.removeEventListener('resize', canvasResizeBehaviour)
  window.removeEventListener('orientationchange', onOrientationchange)

  clearInterval(resizeCheckerInterval)
  resizeCheckerInterval = null

  if (animations?.countries.timeout) {
    clearTimeout(animations.countries.timeout)
  }
  if (canvasResizeBehaviourTimeout) {
    clearTimeout(canvasResizeBehaviourTimeout)
  }
}

/* CAMERA AND CONTROLS */

function addCamera() {
  camera.object = new THREE.PerspectiveCamera(
    60,
    canvas.clientWidth / canvas.clientHeight,
    1,
    10000
  )
  camera.object.position.z = props.globeRadius * 2.2
}

function addControls() {
  const OrbitControls = threeOrbitControls(THREE)
  camera.controls = new OrbitControls(camera.object, canvas)
  camera.controls.enableKeys = false
  camera.controls.enablePan = false
  camera.controls.enableZoom = false
  camera.controls.enableDamping = false
  camera.controls.enableRotate = false

  // Set the initial camera angles to something crazy for the introduction animation
  camera.angles.current.azimuthal = -Math.PI
  camera.angles.current.polar = 0
}

/* RENDERING */

function render() {
  renderer.render(scene, camera.object)
}

function animate() {
  if (!isActive) {
    return
  }

  if (isHidden === false) {
    requestAnimationFrame(animate)
  }

  if (groups.globeDots) {
    introAnimate()
  }

  if (animations.finishedIntro === true) {
    animateDots()
  }

  if (animations.countries.animating === true) {
    animateCountryCycle()
  }

  positionElements()

  camera.controls.update()

  render()
}

/* GLOBE */

function addGlobe() {
  const textureLoader = new THREE.TextureLoader()
  textureLoader.setCrossOrigin(true)

  const radius = props.globeRadius - props.globeRadius * 0.02
  const segments = 64
  const rings = 64

  // Make gradient

  const canvasSize = 128
  const textureCanvas = document.createElement('canvas')
  textureCanvas.width = canvasSize
  textureCanvas.height = canvasSize
  const canvasContext = textureCanvas.getContext('2d')
  if (!canvasContext) {
    return console.error('Context could not be initialized')
  }
  canvasContext.rect(0, 0, canvasSize, canvasSize)
  const canvasGradient = canvasContext.createLinearGradient(0, 0, 0, canvasSize)
  canvasGradient.addColorStop(0, '#664896')
  canvasGradient.addColorStop(0.5, '#3E2C5C')
  canvasGradient.addColorStop(1, '#32234A')
  canvasContext.fillStyle = canvasGradient
  canvasContext.fill()

  // Make texture
  const texture = new THREE.Texture(textureCanvas)
  texture.needsUpdate = true

  const geometry = new THREE.SphereGeometry(radius, segments, rings)
  const material = new THREE.MeshBasicMaterial({
    map: texture,
    transparent: true,
    opacity: 0,
  })
  globe = new THREE.Mesh(geometry, material)

  groups.globe = new THREE.Group()
  groups.globe.name = 'Globe'

  groups.globe.add(globe)
  groups.main.add(groups.globe)

  addGlobeDots()
}

function addGlobeDots() {
  const geometry = new THREE.Geometry()

  // Make circle
  const canvasSize = 16
  const halfSize = canvasSize / 2
  const textureCanvas = document.createElement('canvas')
  textureCanvas.width = canvasSize
  textureCanvas.height = canvasSize
  const canvasContext = textureCanvas.getContext('2d')
  if (!canvasContext) {
    return console.error('Context could not be initialized')
  }
  canvasContext.beginPath()
  canvasContext.arc(halfSize, halfSize, halfSize, 0, 2 * Math.PI)
  canvasContext.fillStyle = props.colours.globeDots
  canvasContext.fill()

  // Make texture
  const texture = new THREE.Texture(textureCanvas)
  texture.needsUpdate = true

  const material = new THREE.PointsMaterial({
    map: texture,
    size: props.globeRadius / 120,
  })

  const addDot = function (targetX: any, targetY: any) {
    // Add a point with zero coordinates
    const point = new THREE.Vector3(0, 0, 0)
    geometry.vertices.push(point)

    // Add the coordinates to a new array for the intro animation
    const result = returnSphericalCoordinates(targetX, targetY)
    animations.dots.points.push(new THREE.Vector3(result.x, result.y, result.z))
  }

  for (const point of globePoints.points) {
    addDot(point.x, point.y)
  }

  Object.values(globePoints.countries).forEach((country) => {
    addDot(country.x, country.y)
  })

  // Add the points to the scene
  groups.globeDots = new THREE.Points(geometry, material)
  groups.globe.add(groups.globeDots)
}

/* COUNTRY LINES AND DOTS */

function addLines() {
  // Create the geometry
  const geometry = new THREE.Geometry()

  Object.keys(globePoints.countries).forEach((countryStart) => {
    const group = new THREE.Group()
    group.name = countryStart

    Object.keys(globePoints.countries).forEach((countryEnd) => {
      // Skip if the country is the same
      if (countryStart === countryEnd) {
        return
      }

      // Get the spatial coordinates
      const result = returnCurveCoordinates(
        globePoints.countries[countryStart].x,
        globePoints.countries[countryStart].y,
        globePoints.countries[countryEnd].x,
        globePoints.countries[countryEnd].y
      )

      // Calcualte the curve in order to get points from
      const curve = new THREE.QuadraticBezierCurve3(
        new THREE.Vector3(result.start.x, result.start.y, result.start.z),
        new THREE.Vector3(result.mid.x, result.mid.y, result.mid.z),
        new THREE.Vector3(result.end.x, result.end.y, result.end.z)
      )

      // Get verticies from curve
      geometry.vertices = curve.getPoints(200)

      // Create mesh line using plugin and set its geometry
      const line = new MeshLine()
      line.setGeometry(geometry)

      // Create the mesh line material using the plugin
      const material = new MeshLineMaterial({
        color: props.colours.lines,
        transparent: true,
        opacity: props.alphas.lines,
      })

      // Create the final object to add to the scene
      const curveObject = new THREE.Mesh(line.geometry, material)
      curveObject._path = geometry.vertices

      group.add(curveObject)
    })

    group.visible = false

    groups.lines.add(group)
  })
}

function addLineDots() {
  /*
		This function will create a number of dots (props.dotsAmount) which will then later be
		animated along the lines. The dots are set to not be visible as they are later
		assigned a position after the introduction animation.
	*/

  const radius = props.globeRadius / 120
  const segments = 32
  const rings = 32

  const geometry = new THREE.SphereGeometry(radius, segments, rings)
  const material = new THREE.MeshBasicMaterial({
    color: props.colours.lineDots,
  })

  // Returns a sphere geometry positioned at coordinates
  const returnLineDot = function () {
    const sphere = new THREE.Mesh(geometry, material)
    return sphere
  }

  for (let i = 0; i < props.dotsAmount; i++) {
    // Get the country path geometry vertices and create the dot at the first vertex
    const targetDot = returnLineDot()
    targetDot.visible = false

    // Add custom variables for custom path coordinates and index
    targetDot._pathIndex = null
    targetDot._path = null

    // Add the dot to the dots group
    groups.lineDots.add(targetDot)
  }
}

function assignDotsToRandomLine(target: any) {
  // Get a random line from the current country
  let randomLine = Math.random() * (animations.countries.selected.children.length - 1)
  randomLine = animations.countries.selected.children[randomLine.toFixed(0)]

  // Assign the random country path to the dot and set the index at 0
  target._path = randomLine._path
}

function reassignDotsToNewLines() {
  for (let i = 0; i < groups.lineDots.children.length; i++) {
    const target = groups.lineDots.children[i]
    if (target._path !== null && target._pathIndex !== null) {
      assignDotsToRandomLine(target)
    }
  }
}

function animateDots() {
  // Loop through the dots children group
  for (let i = 0; i < groups.lineDots.children.length; i++) {
    const dot = groups.lineDots.children[i]

    if (dot._path === null) {
      // Create a random seed as a pseudo-delay
      const seed = Math.random()

      if (seed > 0.99) {
        assignDotsToRandomLine(dot)
        dot._pathIndex = 0
      }
    } else if (dot._path !== null && dot._pathIndex < dot._path.length - 1) {
      // Show the dot
      if (dot.visible === false) {
        dot.visible = true
      }

      // Move the dot along the path vertice coordinates
      dot.position.x = dot._path[dot._pathIndex].x
      dot.position.y = dot._path[dot._pathIndex].y
      dot.position.z = dot._path[dot._pathIndex].z

      // Advance the path index by 1
      dot._pathIndex++
    } else {
      // Hide the dot
      dot.visible = false

      // Remove the path assingment
      dot._path = null
    }
  }
}

/* ELEMENTS */

function createListElements() {
  const pushObject = (coordinates: any, target: any) => {
    // Create the element
    const element = document.createElement('li')

    const targetCountry = globePoints.countries[target]

    element.innerHTML = '<span class="text">' + targetCountry.country + '</span>'

    const object = {
      position: coordinates,
      element,
    }

    // Add the element to the DOM and add the object to the array
    list.appendChild(element)
    elements[target] = object
  }

  // Loop through each country line
  let i = 0

  Object.keys(globePoints.countries).forEach((country) => {
    const group = groups.lines.getObjectByName(country)
    const coordinates = group.children[0]._path[0]

    pushObject(coordinates, country)

    if (country === props.startingCountry) {
      // Set the country cycle index and selected line object for the starting country
      animations.countries.index = i
      animations.countries.selected = groups.lines.getObjectByName(country)

      // Set the line opacity to 0 so they can be faded-in during the introduction animation
      const lineGroup = animations.countries.selected
      lineGroup.visible = true
      for (const line of lineGroup.children) {
        line.material.uniforms.opacity.value = 0
      }
      /*
      for (var ii = 0; ii < lineGroup.children.length; ii++) {
        lineGroup.children[ii].material.uniforms.opacity.value = 0
      }*/

      // Set the target camera angles for the starting country for the introduction animation
      const angles = returnCameraAngles(
        globePoints.countries[country].x,
        globePoints.countries[country].y
      )
      camera.angles.target.azimuthal = angles.azimuthal
      camera.angles.target.polar = angles.polar
    } else {
      i++
    }
  })
}

function positionElements() {
  const widthHalf = canvas.clientWidth / 2
  const heightHalf = canvas.clientHeight / 2

  // Loop through the elements array and reposition the elements
  for (let key in elements) {
    const targetElement = elements[key]

    const position = getProjectedPosition(widthHalf, heightHalf, targetElement.position)

    // Construct the X and Y position strings
    const positionX = position.x + 'px'
    const positionY = position.y + 'px'

    // Construct the 3D translate string
    const style = 'translate3D(' + positionX + ', ' + positionY + ', 0)'

    const elementStyle = targetElement.element.style
    elementStyle.webkitTransform = style
    elementStyle.WebkitTransform = style // Just Safari things (capitalised property name prefix)...
    elementStyle.mozTransform = style
    elementStyle.msTransform = style
    elementStyle.oTransform = style
    elementStyle.transform = style
  }
}

/* INTRO ANIMATIONS */

// Easing reference: https://gist.github.com/gre/1650294

const easeInOutCubic = function (t: number) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}

const easeOutCubic = function (t: number) {
  return --t * t * t + 1
}

const easeInOutQuad = function (t: number) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
}

function introAnimate() {
  if (animations.dots.current <= animations.dots.total) {
    const points = groups.globeDots.geometry.vertices
    const totalLength = points.length

    for (let i = 0; i < totalLength; i++) {
      // Get ease value
      let dotProgress = 1 // ? Fixed to 1 to avoid initial animation
      // var dotProgress = easeInOutCubic(animations.dots.current / animations.dots.total)

      // Add delay based on loop iteration
      dotProgress = dotProgress + dotProgress * (i / totalLength)

      if (dotProgress > 1) {
        dotProgress = 1
      }

      // Move the point
      points[i].x = animations.dots.points[i].x * dotProgress
      points[i].y = animations.dots.points[i].y * dotProgress
      points[i].z = animations.dots.points[i].z * dotProgress

      // Animate the camera at the same rate as the first dot
      if (i === 0) {
        let azimuthalDifference =
          (camera.angles.current.azimuthal - camera.angles.target.azimuthal) * dotProgress
        azimuthalDifference = camera.angles.current.azimuthal - azimuthalDifference

        camera.controls.setAzimuthalAngle(azimuthalDifference)

        let polarDifference =
          (camera.angles.current.polar - camera.angles.target.polar) * dotProgress
        polarDifference = camera.angles.current.polar - polarDifference
        camera.controls.setPolarAngle(polarDifference)
      }
    }

    animations.dots.current++

    // Update verticies
    groups.globeDots.geometry.verticesNeedUpdate = true
  }

  if (
    // animations.dots.current >= animations.dots.total * 0.7 &&
    // animations.countries.active === false
    !animations.countries.active
  ) {
    list.classList.add('active')

    const key = Object.keys(globePoints.countries)[animations.countries.index]
    changeCountry(key, true)

    animations.countries.active = true
  }

  if (
    // animations.countries.active === true &&
    // animations.finishedIntro === false
    !animations.finishedIntro
  ) {
    animations.finishedIntro = true
    // Start country cycle
    animations.countries.timeout = setTimeout(showNextCountry, animations.countries.initialDuration)
    addLineDots()
  }

  if (
    // animations.dots.current >= animations.dots.total * 0.65 &&
    animations.globe.current <= animations.globe.total &&
    animations.finishedIntro
  ) {
    const globeProgress = easeOutCubic(animations.globe.current / animations.globe.total)
    globe.material.opacity = props.alphas.globe * globeProgress

    // Fade-in the country lines
    groups.lines.getObjectByName(props.startingCountry).visible = false // ? Added to fix initial line hide from country 1
    const lines = animations.countries.selected.children
    for (let ii = 0; ii < lines.length; ii++) {
      lines[ii].material.uniforms.opacity.value = 0.5 // ? props.alphas.lines * globeProgress (updated to 0.5 always, cannot trust globeProgress since there's no initial animation)
    }

    animations.globe.current++
  }
}

/* COUNTRY CYCLE */

function changeCountry(key: string, initialize: boolean) {
  if (animations.countries.selected !== undefined) {
    animations.countries.selected.visible = false
  }

  for (const name in elements) {
    if (name === key) {
      elements[name].element.classList.add('active')
    } else {
      elements[name].element.classList.remove('active')
    }
  }

  // Show the select country lines
  animations.countries.selected = groups.lines.getObjectByName(key)
  // ??? Hide parabola lines
  // animations.countries.selected.visible = true

  if (initialize !== true) {
    camera.angles.current.azimuthal = camera.controls.getAzimuthalAngle()
    camera.angles.current.polar = camera.controls.getPolarAngle()

    const targetAngles = returnCameraAngles(
      globePoints.countries[key].x,
      globePoints.countries[key].y
    )
    camera.angles.target.azimuthal = targetAngles.azimuthal
    camera.angles.target.polar = targetAngles.polar

    animations.countries.animating = true
    reassignDotsToNewLines()
  }
}

function animateCountryCycle() {
  if (animations.countries.current <= animations.countries.total) {
    const progress = easeInOutQuad(animations.countries.current / animations.countries.total)

    let azimuthalDifference =
      (camera.angles.current.azimuthal - camera.angles.target.azimuthal) * progress
    azimuthalDifference = camera.angles.current.azimuthal - azimuthalDifference
    camera.controls.setAzimuthalAngle(azimuthalDifference)

    let polarDifference = (camera.angles.current.polar - camera.angles.target.polar) * progress
    polarDifference = camera.angles.current.polar - polarDifference
    camera.controls.setPolarAngle(polarDifference)

    animations.countries.current++
  } else {
    animations.countries.animating = false
    animations.countries.current = 0

    animations.countries.timeout = setTimeout(showNextCountry, animations.countries.duration)
  }
}

function showNextCountry() {
  animations.countries.index++

  if (animations.countries.index >= Object.keys(globePoints.countries).length) {
    animations.countries.index = 0
  }

  const key = Object.keys(globePoints.countries)[animations.countries.index]
  changeCountry(key, false)
}

/* COORDINATE CALCULATIONS */

// Returns an object of 3D spherical coordinates
function returnSphericalCoordinates(latitude: number, longitude: number) {
  /*
		This function will take a latitude and longitude and calcualte the
		projected 3D coordiantes using Mercator projection relative to the
		radius of the globe.

		Reference: https://stackoverflow.com/a/12734509
	*/

  // Convert latitude and longitude on the 90/180 degree axis
  latitude = ((latitude - props.mapSize.width) / props.mapSize.width) * -180
  longitude = ((longitude - props.mapSize.height) / props.mapSize.height) * -90

  // Calculate the projected starting point
  const radius = Math.cos((longitude / 180) * Math.PI) * props.globeRadius
  const targetX = Math.cos((latitude / 180) * Math.PI) * radius
  const targetY = Math.sin((longitude / 180) * Math.PI) * props.globeRadius
  const targetZ = Math.sin((latitude / 180) * Math.PI) * radius

  return {
    x: targetX,
    y: targetY,
    z: targetZ,
  }
}

// Reference: https://codepen.io/ya7gisa0/pen/pisrm?editors=0010
function returnCurveCoordinates(
  latitudeA: number,
  longitudeA: number,
  latitudeB: number,
  longitudeB: number
) {
  // Calculate the starting point
  const start = returnSphericalCoordinates(latitudeA, longitudeA)

  // Calculate the end point
  const end = returnSphericalCoordinates(latitudeB, longitudeB)

  // Calculate the mid-point
  const midPointX = (start.x + end.x) / 2
  const midPointY = (start.y + end.y) / 2
  const midPointZ = (start.z + end.z) / 2

  // Calculate the distance between the two coordinates
  let distance = Math.pow(end.x - start.x, 2)
  distance += Math.pow(end.y - start.y, 2)
  distance += Math.pow(end.z - start.z, 2)
  distance = Math.sqrt(distance)

  // Calculate the multiplication value
  let multipleVal = Math.pow(midPointX, 2)
  multipleVal += Math.pow(midPointY, 2)
  multipleVal += Math.pow(midPointZ, 2)
  multipleVal = Math.pow(distance, 2) / multipleVal
  multipleVal = multipleVal * 0.4 // ? previously 0.7 . Reduced parabolas high

  // Apply the vector length to get new mid-points
  const midX = midPointX + multipleVal * midPointX
  const midY = midPointY + multipleVal * midPointY
  const midZ = midPointZ + multipleVal * midPointZ

  // Return set of coordinates
  return {
    start: {
      x: start.x,
      y: start.y,
      z: start.z,
    },
    mid: {
      x: midX,
      y: midY,
      z: midZ,
    },
    end: {
      x: end.x,
      y: end.y,
      z: end.z,
    },
  }
}

// Returns an object of 2D coordinates for projected 3D position
function getProjectedPosition(width: number, height: number, position: any) {
  /*
		Using the coordinates of a country in the 3D space, this function will
		return the 2D coordinates using the camera projection method.
	*/

  position = position.clone()
  const projected = position.project(camera.object)

  return {
    x: projected.x * width + width,
    y: -(projected.y * height) + height,
  }
}

// Returns an object of the azimuthal and polar angles of a given map latitude and longitude
function returnCameraAngles(latitude: number, longitude: number) {
  /*
		This function will convert given latitude and longitude coordinates that are
		proportional to the map dimensions into values relative to PI (which the
		camera uses as angles).

		Note that the azimuthal angle ranges from 0 to PI, whereas the polar angle
		ranges from -PI (negative PI) to PI (positive PI).

		A small offset is added to the azimuthal angle as angling the camera directly on top of a point makes the lines appear flat.
	*/

  let targetAzimuthalAngle = ((latitude - props.mapSize.width) / props.mapSize.width) * Math.PI
  targetAzimuthalAngle = targetAzimuthalAngle + Math.PI / 2
  targetAzimuthalAngle = targetAzimuthalAngle + 0.1 // Add a small offset

  const targetPolarAngle = (longitude / (props.mapSize.height * 2)) * Math.PI

  return {
    azimuthal: targetAzimuthalAngle,
    polar: targetPolarAngle,
  }
}
