import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { PlacementT, VariantT } from './'

type PopoverT = {
  placement: PlacementT
  maxWidth: number
  variant: VariantT
  fillScreenOnMobile: boolean
}

export const StyledPopover = styled.div<PopoverT>`
  z-index: 1000;
  position: fixed;
  max-width: ${(props) => props.maxWidth}px;
  ${(props) => props.variant === 'dropdown' && `width: 100%;`}

  ${(props) =>
    props.fillScreenOnMobile &&
    css`
      @media (max-width: 767px) {
        height: 100vh !important;
        max-height: 100vh !important;
        width: 100% !important;
        max-width: 100% !important;
        top: 0 !important;
        left: 0 !important;
      }
    `}
`

const scaleIn = keyframes`
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  `

export const AnimatedContent = styled.div<{
  variant: VariantT
  placement: string
  nonScrollable: boolean
}>`
  height: 100%;
  border-radius: 6px;
  animation: ${scaleIn} 0.12s ease-out;

  ${(props) =>
    props.variant === 'tooltip' &&
    css`
      position: relative;
      padding: 7px 10px;
      line-height: 1.6;
      white-space: pre-wrap;
      color: #fff;
      background: #20252c;
      font-size: 12px;
      box-shadow: 0 5px 8px -4px rgba(0, 0, 0, 0.25), 0 0 20px 0 rgba(0, 0, 0, 0.18);

      p:not(:last-of-type) {
        margin-bottom: 18px;
      }
    `}

  ${(props) =>
    props.variant === 'dropdown' &&
    css`
      width: 100%;
      background: #20252c;
      box-shadow: 0 5px 8px -4px rgba(0, 0, 0, 0.25), 0 0 20px 0 rgba(0, 0, 0, 0.18),
        0 40px 34px -16px rgba(0, 0, 0, 0.35);

      ${!props.nonScrollable &&
      css`
        overflow-y: auto;
        &::-webkit-scrollbar {
          size: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 99px;
          background: #20252c;
        }
      `}
    `}
`

export const Triangle = styled.div<{ placement: PlacementT }>`
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;

  ${(props) =>
    props.placement === 'top' &&
    css`
      bottom: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #20252c;
    `}
  ${(props) =>
    props.placement === 'bottom' &&
    css`
      top: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #20252c;
    `}
  ${(props) =>
    props.placement === 'left' &&
    css`
      right: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #20252c;
    `}
      ${(props) =>
    props.placement === 'right' &&
    css`
      left: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #20252c;
    `}
`
