import Navbar from 'components/navbar'
import { ProductMedia } from 'components/product-media'
import { ProductTabs } from 'components/product-tabs'
import { Helmet } from 'react-helmet'
import style from 'styles/pages/products.module.scss'
import { useIsScreenMaxWidth } from 'utils/media-query'

export default function CryptoWallets() {
  const isMax1023 = useIsScreenMaxWidth(1023)

  return (
    <>
      <Helmet>
        <title>Crypto Wallets | H+ Trading Solutions</title>
        <meta name="description" content="Transforming Data into Trading Mastery" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Navbar />

      <main>
        <div className={style.hero}>
          {!isMax1023 && <ProductTabs />}

          <div className={style.centerVideoCont}>
            <div className={style.centerVideoInner}>
              <iframe
                className={style.centerVideoIframe}
                src="https://customer-gqquxhxmrzcqw8ux.cloudflarestream.com/e1362f93749a5227794398bb8b839fc4/iframe?autoplay=true&muted=true&loop=true&controls=false"
              />
            </div>
          </div>

          <div className={style.heroContent}>
            <div>
              <h1>Crypto Wallets</h1>
              <p>
                A comprehensive and secure solution for all your digital asset needs. Our platform
                offers wide-ranging support for various blockchains, tokens, and NFTs, ensuring
                seamless management and control over your funds.
              </p>
              <p>
                Benefit from the combination of hot and cold wallet segregation, advanced AML and
                compliance features, flexible wallet management with granular user permissions that
                provides the ultimate balance between accessibility, security, and scalability for
                institutional and retail clients alike.
              </p>
            </div>
            <div style={{ minWidth: 160, maxWidth: 160 }}>
              <ProductMedia
                images={['/crypto-wallets/1.png', '/crypto-wallets/2.png', '/crypto-wallets/3.png']}
              />
            </div>
          </div>
        </div>

        <div style={{ background: '#9E70E8' }}>
          <div className={style.section}>
            <div>
              <img src="/crypto-wallets/asset-support.png" />
            </div>
            <div>
              <h2>Comprehensive Asset and Coin Support</h2>
              <p>
                HPlus crypto wallets offer extensive support for a diverse range of blockchains and
                networks, including Bitcoin, Litecoin, Dogecoin, Ethereum, BNB Chain, Solana, Tron,
                and Polygon, ensuring compatibility with numerous cryptocurrencies.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className={`${style.section} ${style.sectionReverse}`}>
            <div>
              <h2>Native Token and NFT Integration</h2>
              <p>
                Effortlessly manage popular tokens like ERC-20, SPL, TRC-20, and BEP-20 with the
                latest pricing information. Our wallets also enable users to deposit, withdraw, and
                display a variety of NFTs on Ethereum, Solana, and Polygon using ERC-721, ERC-1155,
                and SPL protocols, complete with token metadata.
              </p>
            </div>
            <div>
              <img src="/crypto-wallets/token-integration.png" />
            </div>
          </div>
        </div>

        <div style={{ background: '#9FE870' }}>
          <div className={style.section}>
            <div>
              <img src="/crypto-wallets/hot-cold.png" />
            </div>
            <div>
              <h2>Hot and Cold Wallet Separation for Enhanced Security</h2>
              <p>
                With HPlus, assets are divided between hot and cold wallets. Hot wallets facilitate
                quick transactions while holding minimal funds, and cold wallets store the majority
                of assets offline in a secure, air-gapped environment. Cold wallets can be
                configured for multi-signature or MFA modes and always require manual approval for
                refilling hot wallets.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className={`${style.section} ${style.sectionReverse}`}>
            <div>
              <h2>Advanced Wallet Management and Customization</h2>
              <p>
                Our wallets allow users to optimize liquidity and security with hot wallet and
                custodial wallet options. Set up wallet policies to restrict transaction initiators,
                limit transaction amounts, and pre-approve recipient addresses. Our Wallets as a
                Service (WaaS) feature enables programmatic wallet creation and management for
                businesses, providing unlimited receive addresses for end-users.
              </p>
            </div>
            <div>
              <img src="/crypto-wallets/management.png" />
            </div>
          </div>
        </div>

        <div style={{ background: '#9E70E8' }}>
          <div className={style.section}>
            <div>
              <img src="/crypto-wallets/compliance.png" />
            </div>
            <div>
              <h2>Robust AML and Compliance Features</h2>
              <p>
                HPlus wallets screen all incoming deposits to determine the source of funds,
                allowing businesses to configure custom AML and compliance policies to prevent
                tainted or sanctioned funds from reaching their wallets.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className={`${style.section} ${style.sectionReverse}`}>
            <div>
              <h2>Collateral Management for Minimizing Counterparty</h2>
              <p>
                Risk Reduce counterparty risk by segregating collateral from trading partners and
                exchanges held at HPlus. Our collateral management solution offers comprehensive
                reporting, ongoing collateral sufficiency monitoring, and segregated collateral
                accounts.
              </p>
            </div>
            <div>
              <img src="/crypto-wallets/counterparty.png" />
            </div>
          </div>
        </div>

        <div style={{ background: '#9FE870' }}>
          <div className={style.section}>
            <div>
              <img src="/crypto-wallets/direct-trading.png" />
            </div>
            <div>
              <h2>Direct Trading from Cold Storage</h2>
              <p>
                Access deep, aggregated liquidity for quality trade execution without conflicts of
                interest. Trade directly from cold storage with our trading features, enabling fast
                and secure transactions without ever leaving qualified and segregated cold storage.
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
