import { throttle } from 'lodash'
import { EventBus } from './event-bus'

const PING_TIMEOUT = 10000
const decoder = new TextDecoder('utf8')

type Timeout = any

export class Socket extends EventBus {
  url: string
  isOpening: boolean
  heartbeatTimer: null | Timeout
  reconnectTimer: null | Timeout
  retries: number
  onBeforeConnect: null | (() => Promise<void>)
  connection?: null | WebSocket
  sendPong: () => void

  constructor(url: string) {
    super()
    this.url = url
    this.isOpening = false
    this.heartbeatTimer = null
    this.reconnectTimer = null
    this.retries = 0
    this.onBeforeConnect = null
    this.sendPong = throttle(() => this.request({ channel: 'pong' }), 10_000)
  }

  async connect() {
    if (this.connection) {
      this.connection.close(4663, 'Reconnect')
    }

    clearTimer(this.heartbeatTimer)
    clearTimer(this.reconnectTimer)

    try {
      if (this.onBeforeConnect) {
        await this.onBeforeConnect()
      }
    } catch {
      return this._timedOut()
    }

    this.connection = new WebSocket(this.url)
    this.connection.binaryType = 'arraybuffer'

    this._onmessage = this._onmessage.bind(this)

    this.connection.onmessage = (messageEvent) => {
      this._onmessage(messageEvent)
    }

    this.isOpening = true

    this.connection.onerror = this._onerror.bind(this)
    this.connection.onclose = this._onclose.bind(this)
    this.connection.onopen = this._onopen.bind(this)
  }

  reconnect() {
    this.connect()
  }

  request(params: { [key: string]: any }) {
    if (this.connection?.readyState !== 1) return
    try {
      this.connection?.send(JSON.stringify(params))
    } catch (error) {
      console.error(error)
    }
  }

  subscribe(type: string, callback: (result: any) => void) {
    const handler = (result: any) => callback(result)
    this.on(type, handler)
    const self = this
    return () => {
      self.off(type, handler)
    }
  }

  setOnBeforeConnect(callback: () => Promise<void>) {
    this.onBeforeConnect = callback
  }

  _onclose(event: CloseEvent) {
    this.isOpening = false
    this.emit('disconnected', event)
    clearTimer(this.heartbeatTimer)
    this.connection = null
    this._reconnect()
  }

  _onerror(event: Event) {
    this.isOpening = false
    this.emit('error', event)
  }

  _onopen(event: Event) {
    this.isOpening = false
    this.emit('connected', event)
    clearTimer(this.reconnectTimer)
    this.retries = 0
    this._refreshHeartbeat()
  }

  _onmessage(event: { data: any }) {
    try {
      const result = JSON.parse(
        typeof event.data === 'string' ? event.data : decoder.decode(event.data)
      )
      this.emit(result.type, result)
      this._refreshHeartbeat()
      return true
    } catch {
      return false
    }
  }

  _refreshHeartbeat() {
    clearTimer(this.heartbeatTimer)
    this.heartbeatTimer = setTimeout(this._timedOut.bind(this), PING_TIMEOUT)
    this.sendPong()
  }

  _timedOut() {
    if (this.connection) {
      this.connection.onclose = null
      this.connection.onopen = null
      this.connection.onerror = null
      this.connection.onmessage = null
      this.connection.close()
    }
    this.connection = null

    const reason = 'No heartbeat received within ' + PING_TIMEOUT + 'ms'
    const code = 4663
    const event = new window.CloseEvent('HeartbeatTimeout', { wasClean: true, code, reason })

    this.emit('disconnected', event)
    this._reconnect()
  }

  _reconnect() {
    if (this.isOpening) return

    this.retries += 1

    this.reconnectTimer = setTimeout(() => {
      this.connect()
    }, Math.min((1 + Math.random()) * Math.pow(1, this.retries) * 100, 10000))
  }
}

const clearTimer = (timer: Timeout | null) => {
  if (timer) clearTimeout(timer)
}
