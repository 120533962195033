import { uniqueId } from 'lodash'
import React, { forwardRef } from 'react'
import { HiddenInput, Label } from './styles'

type CheckboxT = {
  className?: string
  checkboxLabel?: string | React.ReactNode
  checked?: boolean
  defaultChecked?: boolean
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

type CheckboxPropsT = Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof CheckboxT> & CheckboxT

export const Checkbox = forwardRef(
  (
    {
      className,
      checkboxLabel,
      checked,
      value,
      defaultChecked,
      onChange = () => {},
      ...checkboxProps
    }: CheckboxPropsT,
    ref
  ) => {
    const checkboxId = uniqueId('proof-checkbox-')
    const { style, ...otherProps } = checkboxProps

    return (
      <div className={className} style={style}>
        <HiddenInput
          {...otherProps}
          value={value}
          type="checkbox"
          id={checkboxId}
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={(e) => {
            onChange(e)
          }}
          ref={ref as any}
        />
        <Label tabIndex={0} htmlFor={checkboxId}>
          <span>{checkboxLabel}</span>
          <svg width="11" height="11" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
            <path
              transform="translate(-3 -3)"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.2 6.112L6.4147281 9.28 9.96 4"
            />
          </svg>
        </Label>
      </div>
    )
  }
)
