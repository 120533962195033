import { clamp } from 'lodash'
import { PlacementT } from './'

export const calcPopoverPosition = (
  popoverRef: { current: HTMLElement },
  anchorRef: { current: HTMLElement },
  placement: PlacementT,
  maxHeight?: number,
  minHeight?: number,
) => {
  const margin = 12

  const popoverRect = popoverRef.current.getBoundingClientRect()
  const anchorRect = anchorRef.current.getBoundingClientRect()

  let popoverHeight = popoverRect.height

  if (minHeight && popoverHeight < minHeight) {
    popoverHeight = minHeight
  }
  if (popoverHeight > window.innerHeight - 10) {
    popoverHeight = window.innerHeight - 10
  }
  if (maxHeight && popoverHeight > maxHeight) {
    popoverHeight = maxHeight
  }

  const anchorCenterY = anchorRect.top + anchorRect.height / 2
  const anchorCenterX = anchorRect.left + anchorRect.width / 2

  const placements = {
    top: {
      top: anchorRect.top - margin - popoverRect.height,
      left: anchorCenterX - popoverRect.width / 2,
    },
    right: {
      top: anchorCenterY - popoverRect.height / 2,
      left: anchorRect.right + margin,
    },
    bottom: {
      top: anchorRect.bottom + margin,
      left: anchorCenterX - popoverRect.width / 2,
    },
    left: {
      top: anchorCenterY - popoverRect.height / 2,
      left: anchorRect.left - margin - popoverRect.width,
    },
  }

  return restrainPositionToWindowBounds(popoverRect.width, popoverHeight, {
    top: placements[placement].top,
    left: placements[placement].left,
  })
}

const restrainPositionToWindowBounds = (
  popoverWidth: number,
  popoverHeight: number,
  position: { top: number; left: number },
) => {
  const boundPosition = { ...position }

  if (position.top < 0) {
    boundPosition.top = popoverHeight + 15 > window.innerHeight ? 0 : 15
  }
  if (position.left + popoverWidth + 25 > window.innerWidth) {
    boundPosition.left = window.innerWidth - popoverWidth - 25
  }
  if (position.top + popoverHeight > window.innerHeight) {
    boundPosition.top = window.innerHeight - popoverHeight
  }
  if (position.left < 15 || boundPosition.left < 15) {
    boundPosition.left = popoverWidth + 15 > window.innerWidth ? 0 : 15
  }
  return { ...boundPosition, height: popoverHeight }
}

export const calcTrianglePosition = (
  popoverRef: { current: HTMLElement },
  anchorRef: { current: HTMLElement },
  placement: PlacementT,
) => {
  const padding = 5

  const popoverRect = popoverRef.current.getBoundingClientRect()
  const anchorRect = anchorRef.current.getBoundingClientRect()

  const popoverCenterY = popoverRect.top + popoverRect.height / 2
  const popoverCenterX = popoverRect.left + popoverRect.width / 2
  const anchorCenterY = anchorRect.top + anchorRect.height / 2
  const anchorCenterX = anchorRect.left + anchorRect.width / 2

  const placements = {
    top: {
      left: clamp(
        popoverRect.right - anchorCenterX - 10 - (popoverCenterX - anchorCenterX) * 2,
        padding,
        popoverRect.width - padding,
      ),
    },
    right: {
      top: clamp(
        popoverRect.bottom - anchorCenterY - 10 - (popoverCenterY - anchorCenterY) * 2,
        padding,
        popoverRect.height - padding,
      ),
    },
    bottom: {
      left: clamp(
        popoverRect.right - anchorCenterX - 10 - (popoverCenterX - anchorCenterX) * 2,
        padding,
        popoverRect.width - padding,
      ),
    },
    left: {
      top: clamp(
        popoverRect.bottom - anchorCenterY - 10 - (popoverCenterY - anchorCenterY) * 2,
        padding,
        popoverRect.height - padding,
      ),
    },
  }

  return placements[placement]
}
