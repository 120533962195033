import styled from '@emotion/styled'

export const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  height: 22px;
  margin: 0;
  padding-left: 32px;
  cursor: pointer;

  span {
    color: #fff;
    opacity: 0.6;
    font-size: 14px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 4px;
    background: #20252c;
  }

  &:focus {
    outline: none;
  }

  svg {
    display: none;
    position: absolute;
    top: 6px;
    left: 5px;
    color: #fff;
  }
`

export const HiddenInput = styled.input`
  display: none;

  &:checked + label {
    svg {
      display: inline-block;
    }
  }
`
