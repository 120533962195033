import { Link } from 'react-router-dom'
import { Logo } from '../logo'
import style from './styles.module.scss'

const Footer = () => {
  return (
    <nav className={style.footer}>
      <Link to="/" className={style.logo}>
        <Logo width={120} />
      </Link>

      <div className={style.columns}>
        <div className={style.column}>
          <h4>Solutions</h4>
          <Link to="/?s=analysis">AI Market Analysis</Link>
          <Link to="/?s=dynamic">Dynamic Market Risk Management</Link>
          <Link to="/?s=surveillance">Trading Surveillance</Link>
          <Link to="/?s=frequency">High Frequency Market Making</Link>
          <Link to="/?s=launching">Launching New Tokens</Link>
          <Link to="/?s=nft">Non-Fungible Token (NFT) Valuation</Link>
        </div>
        <div className={style.column}>
          <h4>Products</h4>
          <Link to="/products/crypto-wallets">Crypto Wallets</Link>
          <Link to="/products/matching-engine">Matching Engine</Link>
          <Link to="/products/trade-execution-analytics">Trade Execution and Analytics</Link>
          <Link to="/products/market-data-platform">Market Data Platform</Link>
        </div>
        <div className={style.column}>
          <h4>Company</h4>
          <Link to="/about">About</Link>
          <Link to="/jobs">Jobs</Link>
          <Link to="/terms-of-service">Terms of Service</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/contact">Contact Us</Link>
        </div>
      </div>

      <div className={style.bottom}>
        <p>© HPlus Consulting {new Date().getFullYear()}</p>
        <div className={style.disclaimer}>
          <p>
            HPlus Consulting and its product, HPlus Trading Solutions, are strictly technology,
            data, analytics, and algorithm providers and do not engage in any form of investment
            advice, asset management, or trading on behalf of clients. Neither HPlus Consulting nor
            its associated products and services are authorized or regulated by any financial
            regulatory authority. As a result, parties utilizing HPlus Consulting services or
            products may not receive the protections typically provided when interacting with
            regulated entities, such as compensation or ombudsman schemes.
          </p>
          <p>
            The material and information provided by HPlus Consulting and HPlus Trading Solutions
            are intended for informational purposes only and do not constitute an offer or
            solicitation for the purchase or management of any financial instruments or assets. The
            information contained within is not directed at or intended for distribution to, or use
            by, any person or entity in any jurisdiction where such distribution or use would be
            contrary to local law or regulation.
          </p>
          <p>
            Any references to "market making" or "market maker" in the content provided by HPlus
            Consulting, HPlus Trading Solutions, or any associated activities, pertain to liquidity
            provision and do not refer to regulated activities which may bear the same or similar
            names as designated by the Securities and Exchange Commission or other regulatory or
            self-regulatory organizations.
          </p>
          <p>
            The information provided herein is not directed at any investor or category of investors
            and is provided solely as general information about HPlus Consulting's products and
            services and to otherwise offer general investment education. No information contained
            herein should be regarded as a suggestion to engage in or refrain from any
            investment-related course of action, as HPlus Consulting and its affiliates are not
            undertaking to provide investment advice, act as an adviser to any plan or entity, or
            offer advice in a fiduciary capacity concerning the materials presented herein. If you
            are an individual investor, please consult your financial advisor or other fiduciary
            unrelated to HPlus Consulting to determine if any given investment idea, strategy,
            product, or service described herein may be appropriate for your circumstances.
          </p>
        </div>
      </div>
    </nav>
  )
}

export default Footer
