export function setCookie(name: string, value: string | undefined, expiresInDays = 1) {
  if (value === undefined) {
    document.cookie = name + '=; Max-Age=0'
    return
  }
  var expires = ''
  if (expiresInDays) {
    var date = new Date()
    date.setTime(date.getTime() + expiresInDays * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export const getCookie = (name: string) =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
