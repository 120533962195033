import React, { forwardRef } from 'react'
import { TextareaContainer } from './styles'

type TextareaT = {
  value?: string | number
  size?: 'medium' | 'large'
  invalid?: boolean
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onEnter?: () => void
}

export type TextareaPropsT = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  keyof TextareaT
> &
  TextareaT

export const Textarea = forwardRef(
  (
    {
      className,
      size = 'medium',
      invalid = false,
      style,
      containerStyle,
      onChange = () => {},
      onEnter = () => {},
      ...TextareaProps
    }: TextareaPropsT,
    ref
  ) => (
    <TextareaContainer className={className} style={containerStyle} size={size} invalid={invalid}>
      <textarea
        onKeyDown={(event) => {
          if (event.keyCode === 13) onEnter()
        }}
        style={style}
        {...TextareaProps}
        className={undefined}
        ref={ref as any}
        onChange={onChange}
      />
    </TextareaContainer>
  )
)
