import { merge } from 'lodash'
import { safeParseJSON, safeStringifyJSON } from './json'

type KeyT = 'clid'

export const createBrowserStorage = <DataT extends any>(
  key: KeyT,
  options: {
    type?: 'local' | 'session'
    initialState?: Partial<DataT>
  } = {},
) => {
  const storage = options.type === 'session' ? sessionStorage : localStorage
  const store = {
    get: () => {
      return (safeParseJSON(storage.getItem(key)) || {}) as DataT
    },
    set: (data: DataT) => {
      storage.setItem(key, safeStringifyJSON(data))
    },
    merge: (data: Partial<DataT>) => {
      const currentData = safeParseJSON(storage.getItem(key)) || {}
      storage.setItem(key, safeStringifyJSON(merge(currentData, data)))
    },
    clear: () => {
      storage.removeItem(key)
    },
  }
  if (options.initialState) {
    const stored = store.get()
    for (const key in options.initialState) {
      if (stored[key] === undefined) {
        //@ts-ignore
        store.merge({ [key]: options.initialState[key] })
      }
    }
  }
  return store
}
