// @ts-nocheck

import * as React from 'react'

import { loadScene, setVisibility, unloadScene } from './globe3d'

import { injectScript } from 'utils/inject-script'

function isWebGLSupported() {
  return true
}

export default class Globe extends React.Component<any, { unableToLoad: boolean }> {
  jsGlobe: any
  jsCanvas: any
  jsList: any

  constructor(props: any) {
    super(props)
    this.state = {
      unableToLoad: false,
    }
  }

  componentDidMount() {
    injectScript('https://cdnjs.cloudflare.com/ajax/libs/three.js/r99/three.min.js')

    if (isWebGLSupported()) {
      try {
        this.waitForThree(() => {
          loadScene(this.jsGlobe, this.jsCanvas, this.jsList)
          this.listenVisibility()
        })
      } catch (err) {
        // Can't create WebGL context. Globe won't be displayed
        this.setState({ unableToLoad: true })
      }
    }
  }

  //@ts-ignore
  waitForThree(callback) {
    if (typeof THREE === 'undefined') {
      return setTimeout(() => this.waitForThree(callback), 300)
    }
    callback()
  }

  componentWillUnmount() {
    if (isWebGLSupported()) {
      unloadScene()
      this.removeListenVisibility()
    }
  }

  listenVisibility() {
    if ('hidden' in document) {
      document.addEventListener('visibilitychange', this.onFocusChange)
    } else if ('mozHidden' in document) {
      document.addEventListener('mozvisibilitychange', this.onFocusChange)
    } else if ('webkitHidden' in document) {
      document.addEventListener('webkitvisibilitychange', this.onFocusChange)
    } else if ('msHidden' in document) {
      document.addEventListener('msvisibilitychange', this.onFocusChange)
    } else if ('onfocusin' in document) {
      this.onfocusin = document.onfocusin
      this.onfocusin = document.onfocusout
      document.onfocusin = document.onfocusout = this.onFocusChange
    } else {
      this.onfocusin = window.onpageshow
      this.onfocusin = window.onpagehide
      this.onfocusin = window.onfocus
      this.onfocusin = window.onblur
      window.onpageshow = window.onpagehide = window.onfocus = window.onblur = this.onFocusChange
    }
  }

  removeListenVisibility() {
    if ('hidden' in document) {
      document.removeEventListener('visibilitychange', this.onFocusChange)
    } else if ('mozHidden' in document) {
      document.removeEventListener('mozvisibilitychange', this.onFocusChange)
    } else if ('webkitHidden' in document) {
      document.removeEventListener('webkitvisibilitychange', this.onFocusChange)
    } else if ('msHidden' in document) {
      document.removeEventListener('msvisibilitychange', this.onFocusChange)
    } else if ('onfocusin' in document) {
      document.onfocusin = this.onfocusin
      document.onfocusout = this.onfocusout
    } else {
      window.onpageshow = this.onfocusin
      window.onpagehide = this.onfocusin
      window.onfocus = this.onfocusin
      window.onblur = this.onfocusin
    }
  }

  onFocusChange = (event: any) => {
    const visible = 'visible'
    const hidden = 'hidden'
    const eventMap = {
      focus: visible,
      focusin: visible,
      pageshow: visible,
      blur: hidden,
      focusout: hidden,
      pagehide: hidden,
    }

    event = event || window.event

    setVisibility(event.type in eventMap)
  }

  render() {
    if (this.state.unableToLoad) {
      return null
    }

    if (!isWebGLSupported()) {
      return null
    }

    return (
      <div className="globe" ref={(ref) => (this.jsGlobe = ref)}>
        <ul className="globe-list" ref={(ref) => (this.jsList = ref)} />
        <canvas className="globe-canvas" ref={(ref) => (this.jsCanvas = ref)} />
      </div>
    )
  }
}
