import { isEqual } from 'lodash'
import { useRef } from 'react'

export const useDeepCompareMemoize = <T extends any>(value: T): T => {
  const valueRef: any = useRef()

  if (!isEqual(value, valueRef.current)) {
    valueRef.current = value
  }
  return valueRef.current
}
