import React from 'react'
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom'

// This whole file is a dirty hack to be able to use navigate and location outside of react components

const browserRouter: {
  navigate: null | NavigateFunction
  location: null | Location
} = {
  navigate: null,
  location: null,
}

export const router = {
  push: (to: string | { pathname?: string; search?: string }) => browserRouter.navigate?.(to),

  replace: (to: string | { pathname?: string; search?: string }) =>
    browserRouter.navigate?.(to, { replace: true }),

  goBack: () => browserRouter.navigate?.(-1),

  get location(): Location {
    return (
      browserRouter.location || {
        pathname: window.location.pathname,
        search: window.location.search,
        hash: '',
        key: '',
        state: null,
      }
    )
  },
}

export const BrowserRouterLoader = ({ children }: { children: React.ReactElement }) => {
  const navigate = useNavigate()
  const location = useLocation()
  browserRouter.navigate = navigate
  browserRouter.location = location
  return children
}
