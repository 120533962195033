export const Logo = ({ width, color = 'white' }: { width: number; color?: string }) => {
  return (
    <svg
      width={width.toString()}
      viewBox="0 0 173 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="48" fill={color} />
      <rect x="16" y="16" width="16" height="16" fill={color} />
      <mask
        id="mask0_21_3473"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="0"
        width="48"
        height="48"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48 0H32V16H16V32H32V48H48V32H64V16H48V0Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_21_3473)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6681 -21.5858L38.2539 -23L-7.00093 22.2548L-5.58671 23.669L39.6681 -21.5858ZM42.4965 -18.7574L41.0823 -20.1716L-4.1725 25.0833L-2.75829 26.4975L42.4965 -18.7574ZM43.9108 -17.3431L45.325 -15.9289L0.0701407 29.3259L-1.34407 27.9117L43.9108 -17.3431ZM48.1534 -13.1005L46.7392 -14.5147L1.48435 30.7401L2.89857 32.1543L48.1534 -13.1005ZM49.5676 -11.6863L50.9818 -10.2721L5.72699 34.9828L4.31278 33.5685L49.5676 -11.6863ZM53.8103 -7.44365L52.396 -8.85786L7.14121 36.397L8.55542 37.8112L53.8103 -7.44365ZM55.2245 -6.02944L56.6387 -4.61522L11.3838 40.6396L9.96964 39.2254L55.2245 -6.02944ZM59.4671 -1.7868L58.0529 -3.20101L12.7981 42.0538L14.2123 43.468L59.4671 -1.7868ZM60.8813 -0.372583L62.2955 1.04163L17.0407 46.2965L15.6265 44.8822L60.8813 -0.372583ZM65.124 3.87006L63.7097 2.45584L18.4549 47.7107L19.8691 49.1249L65.124 3.87006ZM66.5382 5.28427L67.9524 6.69848L22.6976 51.9533L21.2833 50.5391L66.5382 5.28427ZM70.7808 9.52691L69.3666 8.1127L24.1118 53.3675L25.526 54.7817L70.7808 9.52691ZM72.195 10.9411L73.6092 12.3553L28.3544 57.6102L26.9402 56.196L72.195 10.9411ZM76.4377 15.1838L75.0235 13.7696L29.7686 59.0244L31.1828 60.4386L76.4377 15.1838ZM77.8519 16.598L79.2661 18.0122L34.0113 63.267L32.5971 61.8528L77.8519 16.598ZM82.0945 20.8406L80.6803 19.4264L35.4255 64.6812L36.8397 66.0955L82.0945 20.8406ZM83.5087 22.2548L84.923 23.669L39.6681 68.9239L38.2539 67.5097L83.5087 22.2548ZM87.7514 26.4975L86.3372 25.0833L41.0823 70.3381L42.4965 71.7523L87.7514 26.4975Z"
          fill={color}
        />
      </g>
      <path
        d="M76 13.6719V1.67188H79.2578V6.35938H83.5703V1.67188H86.8281V13.6719H83.5703V8.98438H79.2578V13.6719H76Z"
        fill={color}
      />
      <path
        d="M92.0078 12.0312V3.35938H94.6328V12.0312H92.0078ZM88.9844 9.00781V6.38281H97.6562V9.00781H88.9844Z"
        fill={color}
      />
      <path
        d="M76 20.4609V17.8359H86.4297V20.4609H82.8203V29.8359H79.6094V20.4609H76Z"
        fill={color}
      />
      <path
        d="M87.6558 29.8359V17.8359H92.8354C93.7261 17.8359 94.5054 17.998 95.1733 18.3223C95.8413 18.6465 96.3608 19.1133 96.7319 19.7227C97.103 20.332 97.2886 21.0625 97.2886 21.9141C97.2886 22.7734 97.0972 23.498 96.7144 24.0879C96.3354 24.6777 95.8022 25.123 95.1147 25.4238C94.4312 25.7246 93.6323 25.875 92.7183 25.875H89.6245V23.3438H92.062C92.4448 23.3438 92.771 23.2969 93.0405 23.2031C93.314 23.1055 93.5229 22.9512 93.6675 22.7402C93.8159 22.5293 93.8901 22.2539 93.8901 21.9141C93.8901 21.5703 93.8159 21.291 93.6675 21.0762C93.5229 20.8574 93.314 20.6973 93.0405 20.5957C92.771 20.4902 92.4448 20.4375 92.062 20.4375H90.9136V29.8359H87.6558ZM94.687 24.3281L97.687 29.8359H94.1479L91.2183 24.3281H94.687Z"
        fill={color}
      />
      <path
        d="M101.794 29.8359H98.2788L102.24 17.8359H106.693L110.654 29.8359H107.138L104.513 21.1406H104.419L101.794 29.8359ZM101.138 25.1016H107.748V27.5391H101.138V25.1016Z"
        fill={color}
      />
      <path
        d="M116.427 29.8359H111.81V17.8359H116.38C117.614 17.8359 118.681 18.0762 119.579 18.5566C120.481 19.0332 121.177 19.7207 121.665 20.6191C122.157 21.5137 122.403 22.5859 122.403 23.8359C122.403 25.0859 122.159 26.1602 121.671 27.0586C121.183 27.9531 120.491 28.6406 119.597 29.1211C118.702 29.5977 117.646 29.8359 116.427 29.8359ZM115.067 27.0703H116.31C116.903 27.0703 117.409 26.9746 117.827 26.7832C118.249 26.5918 118.569 26.2617 118.788 25.793C119.011 25.3242 119.122 24.6719 119.122 23.8359C119.122 23 119.009 22.3477 118.782 21.8789C118.56 21.4102 118.231 21.0801 117.798 20.8887C117.368 20.6973 116.841 20.6016 116.216 20.6016H115.067V27.0703Z"
        fill={color}
      />
      <path d="M127.168 17.8359V29.8359H123.911V17.8359H127.168Z" fill={color} />
      <path
        d="M139.095 17.8359V29.8359H136.376L132.041 23.5313H131.97V29.8359H128.712V17.8359H131.478L135.744 24.1172H135.837V17.8359H139.095Z"
        fill={color}
      />
      <path
        d="M148.429 21.7969C148.378 21.5898 148.298 21.4082 148.189 21.252C148.08 21.0918 147.943 20.957 147.779 20.8477C147.619 20.7344 147.431 20.6504 147.216 20.5957C147.005 20.5371 146.773 20.5078 146.519 20.5078C145.972 20.5078 145.505 20.6387 145.119 20.9004C144.736 21.1621 144.443 21.5391 144.24 22.0313C144.041 22.5234 143.941 23.1172 143.941 23.8125C143.941 24.5156 144.037 25.1172 144.228 25.6172C144.419 26.1172 144.705 26.5 145.083 26.7656C145.462 27.0312 145.933 27.1641 146.496 27.1641C146.992 27.1641 147.404 27.0918 147.732 26.9473C148.064 26.8027 148.312 26.5977 148.476 26.332C148.64 26.0664 148.722 25.7539 148.722 25.3945L149.285 25.4531H146.542V23.1328H151.863V24.7969C151.863 25.8906 151.63 26.8262 151.166 27.6035C150.705 28.377 150.068 28.9707 149.255 29.3848C148.447 29.7949 147.519 30 146.472 30C145.304 30 144.279 29.752 143.396 29.2559C142.513 28.7598 141.824 28.0527 141.328 27.1348C140.835 26.2168 140.589 25.125 140.589 23.8594C140.589 22.8672 140.74 21.9883 141.041 21.2227C141.345 20.457 141.767 19.8105 142.306 19.2832C142.845 18.752 143.468 18.3516 144.175 18.082C144.882 17.8086 145.64 17.6719 146.449 17.6719C147.16 17.6719 147.82 17.7734 148.429 17.9766C149.042 18.1758 149.583 18.4609 150.052 18.832C150.525 19.1992 150.906 19.6348 151.195 20.1387C151.484 20.6426 151.66 21.1953 151.722 21.7969H148.429Z"
        fill={color}
      />
      <path
        d="M82.7734 37.9141C82.7422 37.5234 82.5957 37.2188 82.334 37C82.0762 36.7812 81.6836 36.6719 81.1562 36.6719C80.8203 36.6719 80.5449 36.7129 80.3301 36.7949C80.1191 36.873 79.9629 36.9805 79.8613 37.1172C79.7598 37.2539 79.707 37.4102 79.7031 37.5859C79.6953 37.7305 79.7207 37.8613 79.7793 37.9785C79.8418 38.0918 79.9395 38.1953 80.0723 38.2891C80.2051 38.3789 80.375 38.4609 80.582 38.5352C80.7891 38.6094 81.0352 38.6758 81.3203 38.7344L82.3047 38.9453C82.9688 39.0859 83.5371 39.2715 84.0098 39.502C84.4824 39.7324 84.8691 40.0039 85.1699 40.3164C85.4707 40.625 85.6914 40.9727 85.832 41.3594C85.9766 41.7461 86.0508 42.168 86.0547 42.625C86.0508 43.4141 85.8535 44.082 85.4629 44.6289C85.0723 45.1758 84.5137 45.5918 83.7871 45.877C83.0645 46.1621 82.1953 46.3047 81.1797 46.3047C80.1367 46.3047 79.2266 46.1504 78.4492 45.8418C77.6758 45.5332 77.0742 45.0586 76.6445 44.418C76.2187 43.7734 76.0039 42.9492 76 41.9453H79.0938C79.1133 42.3125 79.2051 42.6211 79.3691 42.8711C79.5332 43.1211 79.7637 43.3105 80.0605 43.4395C80.3613 43.5684 80.7188 43.6328 81.1328 43.6328C81.4805 43.6328 81.7715 43.5898 82.0059 43.5039C82.2402 43.418 82.418 43.2988 82.5391 43.1465C82.6602 42.9941 82.7227 42.8203 82.7266 42.625C82.7227 42.4414 82.6621 42.2812 82.5449 42.1445C82.4316 42.0039 82.2441 41.8789 81.9824 41.7695C81.7207 41.6563 81.3672 41.5508 80.9219 41.4531L79.7266 41.1953C78.6641 40.9648 77.8262 40.5801 77.2129 40.041C76.6035 39.498 76.3008 38.7578 76.3047 37.8203C76.3008 37.0586 76.5039 36.3926 76.9141 35.8223C77.3281 35.248 77.9004 34.8008 78.6309 34.4805C79.3652 34.1602 80.207 34 81.1562 34C82.125 34 82.9629 34.1621 83.6699 34.4863C84.377 34.8105 84.9219 35.2676 85.3047 35.8574C85.6914 36.4434 85.8867 37.1289 85.8906 37.9141H82.7734Z"
        fill={color}
      />
      <path
        d="M98.9189 40.1641C98.9189 41.5 98.6592 42.627 98.1396 43.5449C97.6201 44.459 96.9189 45.1523 96.0361 45.625C95.1533 46.0938 94.1689 46.3281 93.083 46.3281C91.9893 46.3281 91.001 46.0918 90.1182 45.6191C89.2393 45.1426 88.54 44.4473 88.0205 43.5332C87.5049 42.6152 87.2471 41.4922 87.2471 40.1641C87.2471 38.8281 87.5049 37.7031 88.0205 36.7891C88.54 35.8711 89.2393 35.1777 90.1182 34.709C91.001 34.2363 91.9893 34 93.083 34C94.1689 34 95.1533 34.2363 96.0361 34.709C96.9189 35.1777 97.6201 35.8711 98.1396 36.7891C98.6592 37.7031 98.9189 38.8281 98.9189 40.1641ZM95.5674 40.1641C95.5674 39.4453 95.4717 38.8398 95.2803 38.3477C95.0928 37.8516 94.8135 37.4766 94.4424 37.2227C94.0752 36.9648 93.6221 36.8359 93.083 36.8359C92.5439 36.8359 92.0889 36.9648 91.7178 37.2227C91.3506 37.4766 91.0713 37.8516 90.8799 38.3477C90.6924 38.8398 90.5986 39.4453 90.5986 40.1641C90.5986 40.8828 90.6924 41.4902 90.8799 41.9863C91.0713 42.4785 91.3506 42.8535 91.7178 43.1113C92.0889 43.3652 92.5439 43.4922 93.083 43.4922C93.6221 43.4922 94.0752 43.3652 94.4424 43.1113C94.8135 42.8535 95.0928 42.4785 95.2803 41.9863C95.4717 41.4902 95.5674 40.8828 95.5674 40.1641Z"
        fill={color}
      />
      <path d="M100.426 46.1641V34.1641H103.684V43.5391H108.536V46.1641H100.426Z" fill={color} />
      <path
        d="M116.954 34.1641H120.212V41.8516C120.212 42.7656 119.993 43.5566 119.556 44.2246C119.122 44.8887 118.517 45.4023 117.739 45.7656C116.962 46.125 116.06 46.3047 115.032 46.3047C113.997 46.3047 113.091 46.125 112.313 45.7656C111.536 45.4023 110.931 44.8887 110.497 44.2246C110.067 43.5566 109.853 42.7656 109.853 41.8516V34.1641H113.11V41.5703C113.11 41.9414 113.192 42.2734 113.356 42.5664C113.521 42.8555 113.747 43.082 114.036 43.2461C114.329 43.4102 114.661 43.4922 115.032 43.4922C115.407 43.4922 115.739 43.4102 116.028 43.2461C116.317 43.082 116.544 42.8555 116.708 42.5664C116.872 42.2734 116.954 41.9414 116.954 41.5703V34.1641Z"
        fill={color}
      />
      <path
        d="M121.432 36.7891V34.1641H131.862V36.7891H128.252V46.1641H125.042V36.7891H121.432Z"
        fill={color}
      />
      <path d="M136.346 34.1641V46.1641H133.088V34.1641H136.346Z" fill={color} />
      <path
        d="M149.515 40.1641C149.515 41.5 149.255 42.627 148.735 43.5449C148.216 44.459 147.515 45.1523 146.632 45.625C145.749 46.0938 144.765 46.3281 143.679 46.3281C142.585 46.3281 141.597 46.0918 140.714 45.6191C139.835 45.1426 139.136 44.4473 138.616 43.5332C138.101 42.6152 137.843 41.4922 137.843 40.1641C137.843 38.8281 138.101 37.7031 138.616 36.7891C139.136 35.8711 139.835 35.1777 140.714 34.709C141.597 34.2363 142.585 34 143.679 34C144.765 34 145.749 34.2363 146.632 34.709C147.515 35.1777 148.216 35.8711 148.735 36.7891C149.255 37.7031 149.515 38.8281 149.515 40.1641ZM146.163 40.1641C146.163 39.4453 146.067 38.8398 145.876 38.3477C145.688 37.8516 145.409 37.4766 145.038 37.2227C144.671 36.9648 144.218 36.8359 143.679 36.8359C143.14 36.8359 142.685 36.9648 142.313 37.2227C141.946 37.4766 141.667 37.8516 141.476 38.3477C141.288 38.8398 141.194 39.4453 141.194 40.1641C141.194 40.8828 141.288 41.4902 141.476 41.9863C141.667 42.4785 141.946 42.8535 142.313 43.1113C142.685 43.3652 143.14 43.4922 143.679 43.4922C144.218 43.4922 144.671 43.3652 145.038 43.1113C145.409 42.8535 145.688 42.4785 145.876 41.9863C146.067 41.4902 146.163 40.8828 146.163 40.1641Z"
        fill={color}
      />
      <path
        d="M161.405 34.1641V46.1641H158.686L154.35 39.8594H154.28V46.1641H151.022V34.1641H153.788L158.053 40.4453H158.147V34.1641H161.405Z"
        fill={color}
      />
      <path
        d="M169.415 37.9141C169.383 37.5234 169.237 37.2188 168.975 37C168.717 36.7812 168.325 36.6719 167.797 36.6719C167.461 36.6719 167.186 36.7129 166.971 36.7949C166.76 36.873 166.604 36.9805 166.502 37.1172C166.401 37.2539 166.348 37.4102 166.344 37.5859C166.336 37.7305 166.362 37.8613 166.42 37.9785C166.483 38.0918 166.581 38.1953 166.713 38.2891C166.846 38.3789 167.016 38.4609 167.223 38.5352C167.43 38.6094 167.676 38.6758 167.961 38.7344L168.946 38.9453C169.61 39.0859 170.178 39.2715 170.651 39.502C171.124 39.7324 171.51 40.0039 171.811 40.3164C172.112 40.625 172.333 40.9727 172.473 41.3594C172.618 41.7461 172.692 42.168 172.696 42.625C172.692 43.4141 172.495 44.082 172.104 44.6289C171.713 45.1758 171.155 45.5918 170.428 45.877C169.706 46.1621 168.836 46.3047 167.821 46.3047C166.778 46.3047 165.868 46.1504 165.09 45.8418C164.317 45.5332 163.715 45.0586 163.286 44.418C162.86 43.7734 162.645 42.9492 162.641 41.9453H165.735C165.754 42.3125 165.846 42.6211 166.01 42.8711C166.174 43.1211 166.405 43.3105 166.702 43.4395C167.002 43.5684 167.36 43.6328 167.774 43.6328C168.122 43.6328 168.413 43.5898 168.647 43.5039C168.881 43.418 169.059 43.2988 169.18 43.1465C169.301 42.9941 169.364 42.8203 169.368 42.625C169.364 42.4414 169.303 42.2812 169.186 42.1445C169.073 42.0039 168.885 41.8789 168.624 41.7695C168.362 41.6563 168.008 41.5508 167.563 41.4531L166.368 41.1953C165.305 40.9648 164.467 40.5801 163.854 40.041C163.245 39.498 162.942 38.7578 162.946 37.8203C162.942 37.0586 163.145 36.3926 163.555 35.8223C163.969 35.248 164.542 34.8008 165.272 34.4805C166.006 34.1602 166.848 34 167.797 34C168.766 34 169.604 34.1621 170.311 34.4863C171.018 34.8105 171.563 35.2676 171.946 35.8574C172.333 36.4434 172.528 37.1289 172.532 37.9141H169.415Z"
        fill={color}
      />
    </svg>
  )
}
