import { api } from 'api/adapter'
import { Checkbox } from 'components/checkbox'
import { Input } from 'components/input'
import Navbar from 'components/navbar'
import { Recaptcha } from 'components/recaptcha'
import { Textarea } from 'components/textarea'
import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import style from 'styles/pages/contact.module.scss'

type FormValues = {
  first: string
  last: string
  title: string
  email: string
  phone: string
  interests: string[]
  message: string
}

export default function Contact() {
  const recaptchaRef = useRef<any>()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [apiError, setApiError] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<FormValues>()

  const onSubmit = async () => {
    setIsSubmitted(false)
    setIsSubmitting(true)
    await recaptchaRef.current.execute()
  }

  const onCaptchaVerify = async (token: string) => {
    const formValues = getValues()
    try {
      await api.post(
        '/api/contact',
        {
          ...formValues,
          interest: formValues.interests.join(', '),
          token,
        },
        { json: false },
      )
      reset()
      setIsSubmitted(true)
    } catch (err) {
      setApiError(err.message)
    }
    setIsSubmitting(false)
  }

  return (
    <>
      <Helmet>
        <title>Contact | H+ Trading Solutions</title>
        <meta name="description" content="Transforming Data into Trading Mastery" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Navbar />

      <main>
        <h1 className={style.heading}>Contact</h1>

        <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={style.flex}>
            <div>
              <label className={style.label}>First Name</label>
              <Input
                {...register('first', { required: true, minLength: 2 })}
                invalid={!!errors.first}
              />
              {errors.first && (
                <div className={style.error}>
                  {errors.first.type === 'required'
                    ? 'This field is required'
                    : 'Must be at least 2 characters'}
                </div>
              )}
            </div>
            <div>
              <label className={style.label}>Last Name</label>
              <Input
                {...register('last', { required: true, minLength: 2 })}
                invalid={!!errors.last}
              />
              {errors.last && (
                <div className={style.error}>
                  {errors.last.type === 'required'
                    ? 'This field is required'
                    : 'Must be at least 2 characters'}
                </div>
              )}
            </div>
          </div>

          <label className={style.label}>Job Title</label>
          <Input {...register('title')} />

          <div className={style.flex}>
            <div>
              <label className={style.label}>Work Email</label>
              <Input
                {...register('email', { required: true, minLength: 3 })}
                invalid={!!errors.email}
              />
              {errors.email && (
                <div className={style.error}>
                  {errors.email.type === 'required'
                    ? 'This field is required'
                    : 'Must be at least 3 characters'}
                </div>
              )}
            </div>
            <div>
              <label className={style.label}>Phone Number</label>
              <Input {...register('phone')} />
            </div>
          </div>

          <label className={style.label}>I am interested in</label>
          <div className={style.checkboxes}>
            {[
              'Non-Fungible Token (NFT) Valuation',
              'Launching New Tokens',
              'Trading Surveillance',
              'Dynamic Market Risk Management',
              'High Frequency Market Making',
              'AI Market Analysis',
              'Managed Crypto Wallets',
              'Matching Engine',
              'Trade Execution and Analytics',
              'Market Data Platform',
            ].map((interest) => (
              <Checkbox
                {...register('interests', { validate: (v) => v.length > 0 })}
                value={interest}
                checkboxLabel={interest}
              />
            ))}
          </div>
          {errors.interests && <div className={style.error}>Please select at least one option</div>}

          <label className={style.label}>Message</label>
          <Textarea {...register('message')} />
          {apiError && <div className={style.error}>{apiError}</div>}
          {isSubmitted && (
            <div className={style.success}>
              Thank you for contacting us. We'll be in touch shortly.
            </div>
          )}

          <button className={style.submitButton} disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
        <Recaptcha
          capRef={recaptchaRef}
          onVerify={onCaptchaVerify}
          onAbort={() => setIsSubmitting(false)}
        />

        <h2 className={style.subheading} style={{ padding: '96px 48px' }}>
          We are a team contributing experience from
        </h2>

        <div className={style.companyGrid}>
          <img src="companies/logo1.svg" />
          <img src="companies/logo2.svg" />
          <img src="companies/logo3.svg" style={{ width: '50%' }} />
          <img src="companies/logo4.svg" />
          <img src="companies/logo5.svg" />
          <img src="companies/logo6.svg" />
          <img src="companies/logo7.svg" />
          <img src="companies/logo8.svg" />
          <img src="companies/logo9.svg" />
          <img src="companies/logo10.svg" />
          <img src="companies/logo11.svg" />
          <img src="companies/logo12.svg" />
          <img src="companies/logo13.svg" />
          <img src="companies/logo14.svg" style={{ width: '60%' }} />
          <img src="companies/logo15.svg" />
          <img src="companies/logo16.svg" style={{ width: '80%' }} />
          <img src="companies/logo17.svg" />
          <img src="companies/logo18.svg" />
        </div>
      </main>
    </>
  )
}
