import { Link, useLocation } from 'react-router-dom'
import style from './styles.module.scss'

export const ProductTabs = () => {
  const { pathname } = useLocation()
  const activePath = pathname.split('/')[pathname.split('/').length - 1]

  const calcClass = (path: string) => {
    if (activePath === path) {
      return `${style.tab} ${style.active}`
    }
    return style.tab
  }

  return (
    <div className={style.tabs}>
      <Link to="/products/crypto-wallets" className={calcClass('crypto-wallets')}>
        Crypto Wallets
      </Link>
      <Link to="/products/matching-engine" className={calcClass('matching-engine')}>
        Matching Engine
      </Link>
      <Link
        to="/products/trade-execution-analytics"
        className={calcClass('trade-execution-analytics')}
      >
        Trade Execution Analytics
      </Link>
      <Link to="/products/market-data-platform" className={calcClass('market-data-platform')}>
        Market Data Platform
      </Link>
    </div>
  )
}
