import { useDynamicImport } from 'utils/use-dynamic-import'

export const CryptoIcon = ({
  name,
  size,
  style,
  className,
}: {
  name: string
  size: number
  style?: React.CSSProperties
  className?: string
}) => {
  const iconUrl = useGetCryptoIconUrl(name, size)

  if (!iconUrl) return null

  return (
    <img
      src={iconUrl}
      style={{
        display: 'inline-block',
        borderRadius: 9999,
        width: size,
        minWidth: size,
        height: 'auto',
        ...style,
      }}
      className={className}
    />
  )
}

export const useGetCryptoIconUrl = (name: string, size: number) => {
  const data = useDynamicImport(() => import('static/coinmarketcap.json'))
  const id = ((data || {}) as any)[name.toLowerCase()]
  if (!id) return null
  const pngSize = size <= 32 ? '64x64' : '128x128'
  return `https://s2.coinmarketcap.com/static/img/coins/${pngSize}/${id}.png`
}
