import { api } from 'api/adapter'
import { router } from 'browser-router'
import { Gauge } from 'components/gauge'
import { Matrix } from 'components/matrix'
import { AutoModal } from 'components/modal'
import Navbar from 'components/navbar'
import { Nfts } from 'components/nfts'
import { SurfaceChart } from 'components/surface-chart'
import { Tickers } from 'components/tickers'
import { Tokens } from 'components/tokens'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import style from 'styles/pages/home.module.scss'
import { timeSince } from 'utils/format'
import { queryStringToObject } from 'utils/url'

type MarketJSON = {
  score?: string
  trending?: string
  summary?: string
  last_update?: string
}

const Home = observer(() => {
  const location = useLocation()
  const scrollToSolution = queryStringToObject(location.search).s || ''

  useEffect(() => {
    const element = document.getElementById(
      typeof scrollToSolution === 'string' ? scrollToSolution : '',
    )
    if (element) {
      setTimeout(() => {
        scrollToElement(element)
        router.replace(location.pathname)
      }, 0)
    }
  }, [scrollToSolution, location.pathname])

  const [isMarketUnavailable, setIsMarketUnavailable] = useState(false)
  const [isExpanded, setIsExpanded] = useState({
    nft: false,
    launching: false,
    surveillance: false,
    dynamic: false,
    frequency: false,
    analysis: false,
  })

  const [market, setMarket] = useState<MarketJSON | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        const json: MarketJSON = await api.get('/dynamic/market.json')
        setMarket(json)
      } catch {
        setIsMarketUnavailable(true)
      }
    })()
  }, [])

  return (
    <>
      <Helmet>
        <title>H+ Trading Solutions</title>
        <meta name="description" content="Transforming Data into Trading Mastery" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <main>
        <div className={style.hero}>
          <Navbar transparent />
          <iframe
            className={style.videoIframe}
            src="https://customer-gqquxhxmrzcqw8ux.cloudflarestream.com/a7fe7a8aad0a1cba936e3ef47d9ab022/iframe?autoplay=true&muted=true&loop=true&controls=false"
          />
          <div className={style.heroContent}>
            <h1 className={style.heading}>Transforming Data into Trading Mastery</h1>
            <p className={style.heroDescription}>
              H+ Consulting is a cutting-edge financial technology company that specializes in
              providing advanced solutions for trading and market data analysis across various asset
              classes.
            </p>
            <div className={style.centerVideoCont}>
              <div className={style.centerVideoInner}>
                <iframe
                  className={style.centerVideoIframe}
                  src="https://customer-gqquxhxmrzcqw8ux.cloudflarestream.com/4284fbe9b5aefd3fd7d0f3a296be261d/iframe?autoplay=true&muted=true&loop=true&controls=false"
                />
              </div>
            </div>
          </div>
        </div>

        {!isMarketUnavailable && (
          <div>
            <div className={style.solutionAnalysis}>
              <div>
                <div>
                  <h2 id="analysis">AI Market Analysis</h2>
                  <p>{market?.summary || 'Loading...'}</p>
                </div>
                <div>
                  <Gauge width={240} score={Number(market?.score || '5')} />
                  {market?.last_update && (
                    <div className={style.lastUpdated}>
                      Last updated {timeSince(Number(new Date(market.last_update + 'Z')))} ago
                    </div>
                  )}
                </div>
              </div>
              {market?.trending && (
                <>
                  {!isExpanded.analysis ? (
                    <button
                      className={style.learnMoreButton}
                      onClick={() => setIsExpanded((state) => ({ ...state, analysis: true }))}
                    >
                      See Trending News
                    </button>
                  ) : (
                    <p style={{ marginTop: 24 }}>{market.trending}</p>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <div style={{ background: '#9FE870' }}>
          <div className={style.solutionDynamic}>
            <div className={style.iframeContainer}>
              <SurfaceChart />
            </div>
            <div>
              <h2 id="dynamic">Dynamic Market Risk Management</h2>
              <p>
                Our dynamic market risk management tools provide comprehensive solutions for
                managing the risks of a large trading book.
              </p>
              {!isExpanded.dynamic ? (
                <button
                  className={style.learnMoreButton}
                  style={{ background: '#101216' }}
                  onClick={() => setIsExpanded((state) => ({ ...state, dynamic: true }))}
                >
                  Learn More
                </button>
              ) : (
                <ul className={style.list}>
                  <li>
                    Real-time dashboards with detailed visualizations to monitor exposures, hedging
                    status, and P&L across various asset classes and strategies
                  </li>
                  <li>
                    Comprehensive offline backtests and stress tests to evaluate risk management
                    strategy effectiveness, efficiency, and adaptability to different market
                    conditions
                  </li>
                  <li>
                    Automated risk limit adjustments based on recent trading activity, market
                    volatility, or high-impact economic event calendars, ensuring proactive risk
                    management
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>

        <div className={style.solutionSurveilance}>
          <h2 id="surveillance">Trading Surveillance</h2>
          <div>
            <Tickers />
            <div>
              <p>
                Robust trading surveillance is crucial for maintaining a fair and transparent
                single-dealer platform. Our advanced surveillance tools offer multiple capabilities.
              </p>
              {!isExpanded.surveillance ? (
                <button
                  className={style.learnMoreButton}
                  onClick={() => setIsExpanded((state) => ({ ...state, surveillance: true }))}
                >
                  Learn More
                </button>
              ) : (
                <ul className={style.list}>
                  <li>
                    Real-time monitoring and advanced analytics to detect unusually high short-term
                    profits, which may indicate mispricings or latency arbitrage, ensuring a fair
                    trading environment
                  </li>
                  <li>
                    Sophisticated trader behavior analysis and classification to detect potential
                    market manipulation, front-running, or other malicious activities
                  </li>
                  <li>
                    Cutting-edge algorithms for identifying coordination between traders, which
                    could signal collusion or coordinated trading strategies, to protect market
                    integrity
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className={style.solutionFrequency}>
            <AutoModal
              maxWidth={1000}
              renderContent={() => (
                <div className={style.matrixModalContainer}>
                  <Matrix />
                </div>
              )}
              renderHandle={() => (
                <div style={{ cursor: 'pointer' }}>
                  <Matrix />
                </div>
              )}
            />
            <div>
              <h2 id="frequency">High Frequency Market Making</h2>
              <p>
                Our state-of-the-art platform enables low-latency market making and arbitrage across
                exchanges through various advanced features.
              </p>
              {!isExpanded.frequency ? (
                <button
                  className={style.learnMoreButton}
                  onClick={() => setIsExpanded((state) => ({ ...state, frequency: true }))}
                >
                  Learn More
                </button>
              ) : (
                <ul className={style.list}>
                  <li>
                    Intuitive tools for managing multiple strategies in various markets
                    simultaneously, allowing for efficient allocation of resources and capital
                  </li>
                  <li>
                    Realistic market simulation frameworks for strategy backtesting, performance
                    reconciliation, and pre-deployment validation
                  </li>
                  <li>
                    Machine learning-powered parameter optimization tools that can be run
                    concurrently on large compute clusters, enabling continuous strategy improvement
                  </li>
                  <li>
                    Advanced data center connectivity management via public internet and private
                    lines, ensuring low-latency execution and data transfer
                  </li>
                  <li>
                    A comprehensive set of proven price predictors based on trade and order book
                    data, enabling more accurate market making and arbitrage strategies
                  </li>
                  <li>
                    Flexible rules for adapting strategies to each exchange's unique market
                    microstructure, maximizing profitability and minimizing market impact
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className={style.solutionLaunching}>
            <div>
              <h2 id="launching">Launching New Tokens</h2>
              <p>
                Our all-inclusive suite of tools streamlines the entire token launch process,
                covering various aspects.
              </p>
              {!isExpanded.launching ? (
                <button
                  className={style.learnMoreButton}
                  onClick={() => setIsExpanded((state) => ({ ...state, launching: true }))}
                >
                  Learn More
                </button>
              ) : (
                <ul className={style.list}>
                  <li>
                    Seamless minting of fungible and non-fungible tokens across multiple blockchains
                    with integrated smart contract support
                  </li>
                  <li>
                    Comprehensive solutions for hosting primary sales, including customizable token
                    sale structures, or airdropping tokens to a large number of holders with ease
                  </li>
                  <li>
                    Creation of secondary centralized and decentralized markets for flexible token
                    trading, price discovery, and liquidity provision, including integration with
                    popular decentralized exchanges
                  </li>
                  <li>
                    Dedicated support for liquidity providers, including incentives and strategies
                    for maintaining healthy order books and market depth
                  </li>
                </ul>
              )}
            </div>
            <div style={{ maxWidth: '100%' }}>
              <Tokens />
            </div>
          </div>
        </div>

        <div style={{ background: '#9E70E8' }}>
          <div className={style.solutionNfts}>
            <div style={{ maxWidth: '100%' }}>
              <Nfts />
            </div>
            <div>
              <h2 id="nft">Non-Fungible Token (NFT) Valuation</h2>
              <p>
                Evaluating unique and often illiquid NFTs is a complex task. Our specialized
                statistical pricing models provide deeper insights by estimating fair valuations for
                specific tokens and predicting future trading price ranges. These models incorporate
                a variety of factors.
              </p>
              {!isExpanded.nft ? (
                <button
                  className={style.learnMoreButton}
                  style={{ background: '#101216' }}
                  onClick={() => setIsExpanded((state) => ({ ...state, nft: true }))}
                >
                  Learn More
                </button>
              ) : (
                <>
                  <ul className={style.list}>
                    <li>
                      Comprehensive analysis of collection trends over time and correlations with
                      the broader NFT market to identify potential value drivers
                    </li>
                    <li>
                      In-depth examination of market demand for particular traits, along with the
                      associated rarity premiums and their impact on NFT prices
                    </li>
                    <li>
                      Advanced outlier detection algorithms for off-market trades, which may result
                      from reporting errors or wash trading, to ensure accurate valuation
                    </li>
                  </ul>
                  <p style={{ marginTop: 24, fontSize: 14 }}>
                    Our models are calibrated using extensive historical trade prices from multiple
                    trading venues. Clients can leverage these valuations for various purposes, such
                    as indicative pricing, strategic NFT buying and selling, portfolio management,
                    and NFT-backed loan pricing.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  )
})

const scrollToElement = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect()
  window.scrollTo({ top: rect.top + window.scrollY - 140, behavior: 'smooth' })
}

export default Home
