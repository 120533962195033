import { AutoModal } from '../modal'
import style from './styles.module.scss'

export const ProductMedia = ({
  images,
  omitFromThumbs = [],
}: {
  images: string[]
  omitFromThumbs?: string[]
}) => {
  return (
    <AutoModal
      maxWidth={800}
      renderHandle={() => (
        <div className={style.images}>
          {images
            .filter((src) => !omitFromThumbs.includes(src))
            .map((src) => (
              <img className={style.image} key={src} src={src} />
            ))}
        </div>
      )}
      renderContent={() => (
        <div className={style.modalImages}>
          {images.map((src) => (
            <img className={style.modalImage} key={src} src={src} />
          ))}
        </div>
      )}
    />
  )
}
