type RequestOptionsT = { data?: any; json?: boolean } & RequestInit

const apiRequest = async (method: string, url: string, options?: RequestOptionsT): Promise<any> => {
  const { data, json = true, ...otherOptions } = options || {}

  try {
    const response = await fetch(url, {
      method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: data ? JSON.stringify(data) : undefined,
      ...otherOptions,
    })

    if (!response.ok) {
      const errorText = await response.text()
      const error = new Error(errorText)
      //@ts-ignore
      error.status = response.status
      modifyErrorMessage(error)
      throw error
    }
    return await (json ? response.json() : response.text())
  } catch (error) {
    modifyErrorMessage(error)
    throw error
  }
}

export const api = {
  get: (url: string, options?: RequestOptionsT) => apiRequest('GET', url, options),
  post: (url: string, data: any, options?: RequestOptionsT) =>
    apiRequest('POST', url, { data, ...options }),
  put: (url: string, data: any, options?: RequestOptionsT) =>
    apiRequest('PUT', url, { data, ...options }),
  delete: (url: string, options?: RequestOptionsT) => apiRequest('DELETE', url, options),
}

const modifyErrorMessage = (error: { message: any }) => {
  if (typeof error.message !== 'string') return

  if (error.message.length > 500) {
    error.message = `connection error, please try to reload the page`
  }
}
