import { autorun } from 'mobx'
import { useLayoutEffect, useState } from 'react'

export const isScreenMaxWidth = (maxWidth: number) =>
  window.matchMedia(`(max-width: ${maxWidth}px)`).matches

export const isScreenMinWidth = (minWidth: number) =>
  window.matchMedia(`(min-width: ${minWidth}px)`).matches

export const useIsScreenMaxWidth = (maxWidth: number) => useMediaQuery(`(max-width:`, maxWidth)

export const useIsScreenMinWidth = (minWidth: number) => useMediaQuery(`(min-width:`, minWidth)

const useMediaQuery = (queryPrefix: string, width: number) => {
  const [matches, setMatches] = useState(false)

  useLayoutEffect(() => {
    let removeListener = () => {}

    const disposeAutorun = autorun(() => {
      const query = window.matchMedia(`${queryPrefix} ${width}px)`)

      const updateMatches = () => setMatches(query.matches)

      updateMatches()
      removeListener()
      matchMediaAddListener(query, updateMatches)

      removeListener = () => {
        matchMediaRemoveListener(query, updateMatches)
      }
    })

    return () => {
      disposeAutorun()
      removeListener()
    }
  }, [queryPrefix, width])

  return matches
}

export const matchMediaAddListener = (query: MediaQueryList, listener: () => void) => {
  if (query?.addEventListener) {
    query.addEventListener('change', listener)
  } else {
    query.addListener(listener)
  }
}

export const matchMediaRemoveListener = (query: MediaQueryList, listener: () => void) => {
  if (query?.removeEventListener) {
    query.removeEventListener('change', listener)
  } else {
    query.removeListener(listener)
  }
}
