import { injectScript } from 'utils/inject-script'

let grecaptchaV2: any = null

export const getRecaptchaV2 = (): Promise<any> =>
  new Promise(async (resolve, reject) => {
    if (grecaptchaV2) {
      resolve(grecaptchaV2)
      return
    }
    const callbackName = 'grecaptchaCallbackV2'
    ;(window as any)[callbackName] = () => {
      //@ts-ignore
      grecaptchaV2 = window.grecaptcha
      resolve(grecaptchaV2)
    }
    try {
      await injectScript(
        `https://www.google.com/recaptcha/api.js?onload=${callbackName}&render=explicit`,
      )
    } catch (error) {
      reject(error)
    }
  })
