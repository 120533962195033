export const safeParseJSON = (str: any) => {
  try {
    return JSON.parse(str)
  } catch {
    return {}
  }
}

export const safeStringifyJSON = (data: any) => {
  try {
    return JSON.stringify(data)
  } catch {
    return '{}'
  }
}
