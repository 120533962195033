import { api } from 'api/adapter'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import style from './styles.module.scss'

type Data = {
  [symbol: string]: {
    [symbol: string]: number
  }
}

export const Matrix = observer(() => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<Data>({})

  useEffect(() => {
    ;(async () => {
      try {
        const response = await api.get('/api/correlation')
        setData(response)
      } catch {}
    })()
  }, [])

  const canvasRef = useRef<any>(null)

  useEffect(() => {
    if (isEmpty(data)) return

    const getColor = (correlation: number) => {
      const red = 0xab3e3a
      const yellow = 0xfff8b4
      const green = 0x006837

      if (correlation <= 0.4) {
        return lerpColor(red, yellow, correlation / 0.4)
      } else {
        return lerpColor(yellow, green, (correlation - 0.4) / 0.6)
      }
    }

    const drawMatrix = () => {
      const canvas = canvasRef.current
      const ctx: CanvasRenderingContext2D = canvas.getContext('2d')
      const dataKeys = Object.keys(data)
      const cellSize = 50
      const margin = 12

      canvas.width = dataKeys.length * cellSize + cellSize + margin
      canvas.height = dataKeys.length * cellSize
      ctx.font = '20px sans-serif'

      for (let i = 0; i < dataKeys.length; i++) {
        for (let j = 0; j < dataKeys.length; j++) {
          const correlation = data[dataKeys[i]][dataKeys[j]]
          ctx.fillStyle = getColor(correlation)
          ctx.fillRect(j * cellSize, i * cellSize, cellSize, cellSize)

          ctx.fillStyle = 'black'
          ctx.fillText(
            correlation.toFixed(2),
            j * cellSize + cellSize / 2 - 20,
            (i + 1) * cellSize - cellSize / 2 + 6,
          )

          if (i === 0) {
            ctx.fillStyle = 'black'
            ctx.fillText(dataKeys[j], j * cellSize + 5, 14)
          }

          if (j === 0) {
            ctx.fillStyle = 'black'
            ctx.fillText(dataKeys[i], 5, i * cellSize + 14)
          }
        }
      }

      const gradient = ctx.createLinearGradient(
        dataKeys.length * cellSize + margin + cellSize / 2,
        0,
        dataKeys.length * cellSize + margin + cellSize / 2,
        dataKeys.length * cellSize,
      )
      gradient.addColorStop(0, '#006837')
      gradient.addColorStop(0.4 / 0.6, '#fff8b4')
      gradient.addColorStop(1, '#ab3e3a')
      ctx.fillStyle = gradient
      ctx.fillRect(dataKeys.length * cellSize + margin, 0, cellSize / 2, dataKeys.length * cellSize)
      ctx.fillStyle = 'black'
      ctx.textAlign = 'center'
      ctx.fillText('1', dataKeys.length * cellSize + margin + cellSize / 4, 15)
      ctx.fillText(
        '0',
        dataKeys.length * cellSize + margin + cellSize / 4,
        dataKeys.length * cellSize - 50,
      )
      ctx.fillText(
        '-0.2',
        dataKeys.length * cellSize + margin + cellSize / 4,
        dataKeys.length * cellSize - 7,
      )
    }

    drawMatrix()
    setIsLoading(false)
  }, [data])

  return (
    <div>
      {isLoading && <div>Loading...</div>}
      <canvas className={style.canvas} ref={canvasRef} />
      <div className={style.live}>
        <div className={style.tag}>
          Live <span className={style.dot} />
        </div>
        <span className={style.label}>15-minute cross-asset correlation</span>
      </div>
    </div>
  )
})

const lerpColor = (a: number, b: number, amount: number) => {
  const ar = a >> 16
  const ag = (a >> 8) & 0xff
  const ab = a & 0xff

  const br = b >> 16
  const bg = (b >> 8) & 0xff
  const bb = b & 0xff

  const rr = ar + amount * (br - ar)
  const rg = ag + amount * (bg - ag)
  const rb = ab + amount * (bb - ab)

  return `rgb(${Math.round(rr)}, ${Math.round(rg)}, ${Math.round(rb)})`
}

/* function getFirstX<T>(obj: Record<string, T>, limit: number): Record<string, T> {
  const result: Record<string, T> = {}
  let count = 0

  for (const key in obj) {
    if (count < limit) {
      result[key] = obj[key]
      count++
    } else {
      break
    }
  }

  return result
}
 */
