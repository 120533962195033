import { CryptoIcon } from 'components/crypto-icon'
import { observer } from 'mobx-react-lite'
import { getInstrumentPriceDirection, getTickers, symbols } from 'state/tickers'
import { formatNumber } from 'utils/format'
import { useIsScreenMaxWidth } from 'utils/media-query'
import style from './styles.module.scss'

export const Tickers = observer(() => {
  return (
    <div className={style.tickers}>
      {symbols.map((symbol) => (
        <Ticker key={symbol} symbol={symbol} />
      ))}
    </div>
  )
})

const Ticker = observer(({ symbol }: { symbol: string }) => {
  const ticks = getTickers(symbol)
  const lastTick = ticks[ticks.length - 1]

  return (
    <div className={style.ticker}>
      <div className={style.info}>
        <div className={style.flex}>
          <CryptoIcon name={symbol} size={20} />
          <div>{symbol}</div>
        </div>
        <div style={{ opacity: 0.6 }}>{formatNumber(lastTick?.px || 0, { precision: 'auto' })}</div>
      </div>
      <div className={style.graph}>
        <PriceGraphMemo symbol={symbol} />
      </div>
    </div>
  )
})

const PriceGraphMemo = observer(({ symbol }: { symbol: string }) => {
  const isMax1099 = useIsScreenMaxWidth(1099)

  const graphWidth = isMax1099 ? 80 : 110
  const graphHeight = 35
  const maxTicksToDisplay = 80

  const prices = getTickers(symbol)
    .map((tick) => tick.px)
    .slice(-maxTicksToDisplay)

  if (prices.length < 2) return null

  const minPrice = Math.min(...prices)
  const maxPrice = Math.max(...prices)

  const points = prices.map((price, i) => {
    const left = (graphWidth / (prices.length - 1)) * i
    const top = graphHeight - ((price - minPrice) / (maxPrice - minPrice)) * graphHeight
    return `${left},${top}`
  })

  const priceDirection = getInstrumentPriceDirection(symbol)

  return (
    <svg
      viewBox={`0 0 ${graphWidth} ${graphHeight}`}
      style={{ width: graphWidth, height: graphHeight }}
    >
      <polyline
        fill="none"
        stroke={priceDirection === 'up' ? '#9FE870' : '#FF4949'}
        strokeWidth="2.5"
        points={points.join(' ')}
      />
    </svg>
  )
})
