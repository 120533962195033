export const Arrow = ({ width, color = 'white' }: { width: number; color?: string }) => {
  return (
    <svg
      width={width.toString()}
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9609 24L12.7656 20.8149L19.4811 14.1211H0V9.87842H19.4806L12.7656 3.18503L15.9609 0L27.9997 12L15.9609 24Z"
        fill={color}
      />
    </svg>
  )
}
