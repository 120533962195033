import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const InputContainer = styled.div<{ invalid?: boolean; size: 'medium' | 'large' }>`
  display: flex;
  align-items: center;
  height: ${(props) => sizes[props.size].height}px;
  min-height: ${(props) => sizes[props.size].height}px;
  width: 100%;
  padding: 0 6px 0 16px;
  border-radius: 6px;
  border: 1px solid transparent;
  background: #20252c;
  transition: background 0.1s;

  &:focus-within {
    border: 1px solid #fff;
    background: none;
  }

  ${(props) =>
    props.invalid &&
    css`
      &,
      &:focus-within {
        border: 1px solid #ff4949;
      }
    `}

  input, textarea {
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 16px;

    &,
    &:focus,
    &:hover,
    &:active {
      outline: none;
      border: none;
      background: none;
    }
    &:disabled {
      opacity: 0.4;
    }
    &::placeholder {
      opacity: 0.4;
    }

    /* HACK to remove chrome changing input background after autofill, it will probably stop working one day */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: all 5000s ease-in-out 0s;
    }
  }
`

const sizes = {
  medium: { height: 40, fontSize: 14 },
  large: { height: 50, fontSize: 15 },
}
