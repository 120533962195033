import Navbar from 'components/navbar'
import { ProductMedia } from 'components/product-media'
import { ProductTabs } from 'components/product-tabs'
import { Helmet } from 'react-helmet'
import style from 'styles/pages/products.module.scss'
import { useIsScreenMaxWidth } from 'utils/media-query'

export default function TradeExecutionAnalytics() {
  const isMax1023 = useIsScreenMaxWidth(1023)

  return (
    <>
      <Helmet>
        <title>Trade Execution and Analytics | H+ Trading Solutions</title>
        <meta name="description" content="Transforming Data into Trading Mastery" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Navbar />

      <main>
        <div className={style.hero}>
          {!isMax1023 && <ProductTabs />}

          <div className={style.centerVideoCont}>
            <div className={style.centerVideoInner}>
              <iframe
                className={style.centerVideoIframe}
                src="https://customer-gqquxhxmrzcqw8ux.cloudflarestream.com/e1362f93749a5227794398bb8b839fc4/iframe?autoplay=true&muted=true&loop=true&controls=false"
              />
            </div>
          </div>

          <div className={style.heroContent}>
            <div>
              <h1 style={{ fontSize: 64 }}>Trade Execution And Analytics</h1>
              <p>
                HPlus offers a comprehensive, multi-asset trading solution with advanced execution
                algorithms, cross-platform liquidity sourcing, in-depth transaction cost analysis,
                and powerful backtesting capabilities.
              </p>
              <p>
                This cutting-edge product ensures optimal trade execution, reduced trading costs,
                and maximized returns for clients in various market environments.
              </p>
            </div>
            <div style={{ minWidth: 160, maxWidth: 160 }}>
              <ProductMedia
                images={[
                  '/trade-execution/1.png',
                  '/trade-execution/2.png',
                  '/trade-execution/3.png',
                ]}
              />
            </div>
          </div>
        </div>

        <div style={{ background: '#9E70E8' }}>
          <div className={style.section}>
            <div>
              <img src="/trade-execution/expertise.jpg" />
            </div>
            <div>
              <h2>Multi-Asset Expertise</h2>
              <p>
                HPlus's Trade Execution and Analytics boasts deep expertise spanning multiple asset
                classes, leveraging decades of experience in institutional bonds, futures trading,
                equity HFT, retail FX market making, and crypto liquidity provisioning. This diverse
                experience ensures clients benefit from a comprehensive understanding of various
                market segments, providing tailored solutions for their trading requirements.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className={`${style.section} ${style.sectionReverse}`}>
            <div>
              <h2>Advanced Execution Algorithms</h2>
              <p>
                HPlus employs no-nonsense execution algorithms that use a variety of techniques and
                price signals to minimize slippage and market impact, going beyond simply targeting
                VWAP/TWAP benchmarks. This approach ensures optimal trade execution and helps
                clients achieve their desired outcomes in various market conditions.
              </p>
            </div>
            <div>
              <img src="/trade-execution/algorithms.png" />
            </div>
          </div>
        </div>

        <div style={{ background: '#9FE870' }}>
          <div className={style.section}>
            <div>
              <img src="/trade-execution/liquidity.png" />
            </div>
            <div>
              <h2>Cross-Platform Liquidity Sourcing</h2>
              <p>
                To secure the best prices and minimize market impact, HPlus's Trade Execution and
                Analytics sources liquidity from a multitude of exchanges and trading platforms
                through low-latency direct connectivity. This approach ensures clients have access
                to a wide range of liquidity sources, maximizing their trading opportunities.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className={`${style.section} ${style.sectionReverse}`}>
            <div>
              <h2>Transaction Cost Analysis (TCA)</h2>
              <p>
                HPlus offers a comprehensive TCA solution that empowers clients to visualize and
                review their trades and market data, and benchmark their execution against arrival
                price, VWAP, and measure post-trade price reversion. This in-depth analysis enables
                clients to understand the true total cost of trading, gain insights into their
                trading performance, and make informed decisions to optimize their trading
                strategies.
              </p>
            </div>
            <div>
              <img src="/trade-execution/transaction.png" />
            </div>
          </div>
        </div>

        <div style={{ background: '#9E70E8' }}>
          <div className={style.section}>
            <div>
              <img src="/trade-execution/backtesting.png" />
            </div>
            <div>
              <h2>Backtesting Capabilities</h2>
              <p>
                HPlus's Trade Execution and Analytics allows clients to run historical backtests to
                better understand their impact on the market and optimize their execution
                strategies. By analyzing past performance in various market conditions, clients can
                fine-tune their trading approaches to maximize returns and minimize risk.
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
