import { MenuIcon } from 'components/menu-icon'
import { Popover } from 'components/popover'
import { Link } from 'react-router-dom'
import { useIsScreenMaxWidth } from 'utils/media-query'
import { Logo } from '../logo'
import style from './styles.module.scss'

const Navbar = ({ transparent }: { transparent?: boolean }) => {
  const isMax767 = useIsScreenMaxWidth(767)

  return isMax767 ? (
    <NavbarMobile transparent={transparent} />
  ) : (
    <NavbarDesktop transparent={transparent} />
  )
}

const NavbarDesktop = ({ transparent }: { transparent?: boolean }) => {
  return (
    <nav className={style.navbar} style={transparent ? { background: 'none' } : {}}>
      <Link to="/" className={style.logo}>
        <Logo width={173} />
      </Link>
      <div className={style.navLinks}>
        <div className={style.navLink}>
          <Link to="/?s=nft">Solutions</Link>
          <div className={style.dropdownCont} style={{ width: 340 }}>
            <div className={style.dropdown}>
              <Link to="/?s=analysis">AI Market Analysis</Link>
              <Link to="/?s=dynamic">Dynamic Market Risk Management</Link>
              <Link to="/?s=surveillance">Trading Surveillance</Link>
              <Link to="/?s=frequency">High Frequency Market Making</Link>
              <Link to="/?s=launching">Launching New Tokens</Link>
              <Link to="/?s=nft">Non-Fungible Token (NFT) Valuation</Link>
            </div>
          </div>
        </div>
        <div className={style.navLink}>
          <Link to="/products/crypto-wallets">Products</Link>
          <div className={style.dropdownCont} style={{ width: 300 }}>
            <div className={style.dropdown}>
              <Link to="/products/crypto-wallets">Crypto Wallets</Link>
              <Link to="/products/matching-engine">Matching Engine</Link>
              <Link to="/products/trade-execution-analytics">Trade Execution and Analytics</Link>
              <Link to="/products/market-data-platform">Market Data Platform</Link>
            </div>
          </div>
        </div>
        <Link to="/about" className={style.navLink}>
          About
        </Link>
        <Link to="/jobs" className={style.navLink}>
          Jobs
        </Link>
        <Link to="/contact" className={style.navLink}>
          Contact
        </Link>
      </div>
    </nav>
  )
}

const NavbarMobile = ({ transparent }: { transparent?: boolean }) => {
  return (
    <nav className={style.navbar} style={transparent ? { background: 'none' } : {}}>
      <Link to="/" className={style.logo}>
        <Logo width={173} />
      </Link>

      <Popover
        renderContent={(popover) => (
          <div className={style.mobileDropdown} onClick={popover.close}>
            <div className={style.mobileTitle}>Solutions</div>
            <Link to="/?s=analysis" className={style.mobileLink}>
              AI Market Analysis
            </Link>
            <Link to="/?s=dynamic" className={style.mobileLink}>
              Dynamic Market Risk Management
            </Link>
            <Link to="/?s=surveillance" className={style.mobileLink}>
              Trading Surveillance
            </Link>
            <Link to="/?s=frequency" className={style.mobileLink}>
              High Frequency Market Making
            </Link>
            <Link to="/?s=launching" className={style.mobileLink}>
              Launching New Tokens
            </Link>
            <Link to="/?s=nft" className={style.mobileLink}>
              Non-Fungible Token (NFT) Valuation
            </Link>
            <div className={style.mobileTitle}>Products</div>
            <Link className={style.mobileLink} to="/products/crypto-wallets">
              Crypto Wallets
            </Link>
            <Link className={style.mobileLink} to="/products/matching-engine">
              Matching Engine
            </Link>
            <Link className={style.mobileLink} to="/products/trade-execution-analytics">
              Trade Execution and Analytics
            </Link>
            <Link className={style.mobileLink} to="/products/market-data-platform">
              Market Data Platform
            </Link>
            <div className={style.mobileTitle}>Other</div>
            <Link className={style.mobileLink} to="/about">
              About
            </Link>
            <Link className={style.mobileLink} to="/jobs">
              Jobs
            </Link>
            <Link className={style.mobileLink} to="/contact">
              Contact
            </Link>
          </div>
        )}
      >
        <div>
          <MenuIcon width={24} color="#fff" />
        </div>
      </Popover>
    </nav>
  )
}

export default Navbar
