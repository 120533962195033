const injectedScripts: { [url: string]: boolean } = {}
const loadedScripts: { [url: string]: boolean } = {}

export const injectScript = (url: string): Promise<void> =>
  new Promise((resolve, reject) => {
    if (loadedScripts[url]) return resolve()
    if (injectedScripts[url]) return

    const script = document.createElement('script')
    script.defer = true
    script.async = true
    script.src = url

    script.onload = () => {
      loadedScripts[url] = true
      resolve()
    }
    script.onerror = reject

    injectedScripts[url] = true
    document.body.append(script)
  })
