import React, { forwardRef } from 'react'
import { InputContainer } from './styles'

type InputT = {
  value?: string | number
  size?: 'medium' | 'large'
  invalid?: boolean
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  before?: React.ReactNode
  after?: React.ReactNode
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onEnter?: () => void
}

export type InputPropsT = Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputT> & InputT

export const Input = forwardRef(
  (
    {
      className,
      size = 'medium',
      invalid = false,
      style,
      containerStyle,
      before,
      after,
      onChange = () => {},
      onEnter = () => {},
      ...inputProps
    }: InputPropsT,
    ref
  ) => (
    <InputContainer className={className} style={containerStyle} size={size} invalid={invalid}>
      {before}
      <input
        type="text"
        onKeyDown={(event) => {
          if (event.keyCode === 13) onEnter()
        }}
        style={style}
        {...inputProps}
        className={undefined}
        ref={ref as any}
        onChange={onChange}
      />
      {after}
    </InputContainer>
  )
)
