const units = ['K', 'M', 'B', 'T']

const abbreviate = function (number: number) {
  const dec = Math.pow(10, 6)
  let num = number
  let unit = ''

  if (num < 1000) return toPrecision(num)

  for (var i = units.length - 1; i >= 0; i--) {
    var size = Math.pow(10, (i + 1) * 3)

    if (size <= num) {
      num = Math.round((num * dec) / size) / dec

      if (num === 1000 && i < units.length - 1) {
        num = 1
        i++
      }

      unit = units[i]

      break
    }
  }

  return `${toPrecision(num)}${unit}`
}

const toPrecision = (num: number) => {
  return Number(num.toFixed(calcPrecision(num))).toString()
}

const calcPrecision = (num: number) => {
  const strInt = num.toString().split('.')[0]
  if (strInt.length === 1) return 2
  if (strInt.length === 2) return 1
  return 0
}

export const abbreviateNumber = (number: number) => {
  return `${number < 0 ? '-' : ''}${abbreviate(Math.abs(number))}`
}
