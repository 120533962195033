import { useEffect, useState } from 'react'

export const useDynamicImport = <ModuleT extends any>(importModule: () => Promise<ModuleT>) => {
  const [module, setModule] = useState<ModuleT | null>(null)

  useEffect(() => {
    importModule().then((module) => {
      setModule(module)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return module
}
