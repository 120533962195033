import { useEffect } from 'react'

export const useOnEscapeKeyDown = (isListening: boolean, onEscapeKeyDown: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: Event) => {
      if ((event as KeyboardEvent).keyCode === 27 && !isFocusedElementEditable()) {
        onEscapeKeyDown()
      }
    }

    if (isListening) {
      document.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isListening, onEscapeKeyDown])
}

const isFocusedElementEditable = () =>
  !!document!.activeElement!.getAttribute('contenteditable') ||
  ['TEXTAREA', 'INPUT'].includes(document!.activeElement!.tagName)
