import { isNil, omit, omitBy, pick } from 'lodash'
import queryString, { ParseOptions, StringifyOptions } from 'query-string'

export const objectToQueryString = (obj: any, options: StringifyOptions = {}) =>
  queryString.stringify(omitBy(obj, isNil), {
    arrayFormat: 'bracket',
    ...options,
  })

export const queryStringToObject = (str: string, options: ParseOptions = {}) =>
  queryString.parse(str, {
    arrayFormat: 'bracket',
    ...options,
  })

export const pickFromQueryString = (str: string, keys: string[]) =>
  objectToQueryString(pick(queryStringToObject(str), keys))

export const omitFromQueryString = (str: string, keys: string[]) =>
  objectToQueryString(omit(queryStringToObject(str), keys))

export const addToQueryString = (str: string, params: any) =>
  objectToQueryString({
    ...queryStringToObject(str),
    ...params,
  })

export const joinPathnameQuery = (pathname: string, query: string) =>
  `${pathname}${query ? '?' : ''}${query}`.replace('??', '?')

export const splitPathnameQuery = (url: string) => ({
  pathname: url.split('?')[0],
  query: url.split('?')[1] || '',
})

export const joinURL = (parts: any[]) => parts.filter((part) => !!part).join('/')
