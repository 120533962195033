import Navbar from 'components/navbar'
import { ProductMedia } from 'components/product-media'
import { ProductTabs } from 'components/product-tabs'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import style from 'styles/pages/products.module.scss'
import { useIsScreenMaxWidth } from 'utils/media-query'

export default function MarketDataPlatform() {
  const [isExpanded, setIsExpanded] = useState({
    backtesting: false,
  })
  const isMax1023 = useIsScreenMaxWidth(1023)

  return (
    <>
      <Helmet>
        <title>Market Data Platform | H+ Trading Solutions</title>
        <meta name="description" content="Transforming Data into Trading Mastery" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Navbar />

      <main>
        <div className={style.hero}>
          {!isMax1023 && <ProductTabs />}

          <div className={style.centerVideoCont}>
            <div className={style.centerVideoInner}>
              <iframe
                className={style.centerVideoIframe}
                src="https://customer-gqquxhxmrzcqw8ux.cloudflarestream.com/e1362f93749a5227794398bb8b839fc4/iframe?autoplay=true&muted=true&loop=true&controls=false"
              />
            </div>
          </div>

          <div className={style.heroContent}>
            <div>
              <h1>Market Data Platform</h1>
              <p>
                HPlus offers a multi-faceted platform for advanced order book visualization,
                time-series data warehousing, high-performance data processing, real-time analytics,
                and customizable dashboards. The product is designed to handle various asset
                classes, from fixed income to equities to options and crypto, with unmatched
                efficiency.
              </p>
            </div>
            <div style={{ minWidth: 160, maxWidth: 160 }}>
              <ProductMedia
                images={[
                  '/market-platform/1.png',
                  '/market-platform/2.png',
                  '/market-platform/3.png',
                ]}
              />
            </div>
          </div>
        </div>

        <div style={{ background: '#9E70E8' }}>
          <div className={style.section}>
            <div>
              <img src="/market-platform/visualisation.png" />
            </div>
            <div>
              <h2>Advanced Order Book Visualization</h2>
              <p>
                Experience live and historical advanced order book visualization with individual
                order TCA tools for in-depth execution quality investigation, market impact
                analysis, and alpha ideas research. Leverage built-in analytical functions
                specifically designed for trading and quantitative research.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className={`${style.section} ${style.sectionReverse}`}>
            <div>
              <h2>Time-series data warehousing</h2>
              <p>
                Benefit from a platform highly optimized for storing, processing, and analyzing
                time-series data, equipped with a fully-fledged matching engine module for
                reconstructing the complete order book and running historical backtests. Harness the
                power of time-series analytics to unlock valuable insights across various industries
                and use cases.
              </p>
            </div>
            <div>
              <img src="/market-platform/warehousing.png" />
            </div>
          </div>
        </div>

        <div style={{ background: '#9FE870' }}>
          <div className={style.section}>
            <div>
              <img src="/market-platform/performance.png" />
            </div>
            <div>
              <h2>High performance</h2>
              <p>
                Utilize a custom-built petabyte-scale tick historical and intraday data warehouse
                capable of handling L1, L2, and L3 market data from all asset classes, ranging from
                fixed income to equities, options, and cryptocurrencies. The platform is designed
                for rapid data processing, accommodating large data volumes without compromising on
                performance.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className={`${style.section} ${style.sectionReverse}`}>
            <div>
              <h2>Data Streaming</h2>
              <p>
                Rely on a highly available real-time analytics and data capture framework that
                processes over 10 billion values per day for signal generation, intraday charting,
                and order flow matching engine reconstruction. The platform offers seamless
                integration with various data sources and supports popular tools, ensuring a smooth
                and efficient data streaming experience.
              </p>
            </div>
            <div>
              <img src="/market-platform/streaming.png" />
            </div>
          </div>
        </div>

        <div style={{ background: '#9E70E8' }}>
          <div className={style.section}>
            <div>
              <img src="/market-platform/dashboards.png" />
            </div>
            <div>
              <h2>Dashboards</h2>
              <p>
                Empower your decision-making process with powerful and highly customizable
                historical and real-time interactive dashboards and data-cubes for visualizing all
                of your market and trading data. The intuitive user interface allows for easy data
                manipulation and sharing, while the platform's support for multiple visualization
                options ensures optimal data presentation.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div
            className={`${style.section} ${style.sectionReverse}`}
            style={{ borderBottom: `1px solid #20252C` }}
          >
            <div>
              <h2>Backtesting Capabilities</h2>
              <p>
                Effortlessly conduct thorough backtesting of your trading strategies with an
                advanced and versatile backtesting framework, designed to accommodate a wide range
                of historical market data and custom scenarios. Test your strategies against
                different market conditions, asset classes, and timeframes to optimize performance
                and minimize risk. Key features include:
              </p>
              {!isExpanded.backtesting ? (
                <button
                  className={style.learnMoreButton}
                  onClick={() => setIsExpanded((state) => ({ ...state, backtesting: true }))}
                >
                  Learn More
                </button>
              ) : (
                <>
                  <ul className={style.list}>
                    <li>
                      Comprehensive backtesting engine: Simulate your trading strategies with high
                      fidelity, leveraging a robust backtesting engine that accounts for various
                      factors, such as slippage, latency, and liquidity constraints.
                    </li>
                    <li>
                      Flexible strategy configurations: Easily adapt and test different variations
                      of your trading strategies with customizable parameters, allowing for a
                      thorough exploration of potential optimizations and improvements.
                    </li>
                    <li>
                      Performance analysis and reporting: Analyze the performance of your strategies
                      with detailed metrics, including risk-adjusted returns, drawdowns, and
                      performance attribution, presented through intuitive and interactive reports.
                    </li>
                    <li>
                      Scenario analysis: Stress-test your strategies with custom scenario analysis,
                      taking into account market events, changes in market structure, and other
                      relevant factors to ensure your strategies remain robust in the face of
                      uncertainty.
                    </li>
                    <li>
                      Integration with other tools: Seamlessly integrate the backtesting
                      capabilities with the platform's other features, such as advanced order book
                      visualization, time-series data warehousing, and data streaming, for a
                      comprehensive and efficient trading research process.
                    </li>
                  </ul>
                </>
              )}
            </div>
            <div>
              <img src="/market-platform/backtesting.png" />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
