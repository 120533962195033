import { api } from 'api/adapter'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { abbreviateNumber } from 'utils/abbreviate-number'
import { formatNumber, formatPercentage } from 'utils/format'
import { useIsScreenMaxWidth } from 'utils/media-query'
import { Arrow } from '../arrow'
import style from './styles.module.scss'

type Token = {
  history?: { px: number; ts: string }[]
  market_cap: number
  name: string
  percent_change_24h: number
  price: number
  symbol: string
}

export const Tokens = observer(() => {
  const [tokens, setTokens] = useState<Token[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        const tokens = await api.get('/api/trending')
        setTokens(Object.values(tokens))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  const isMax767 = useIsScreenMaxWidth(767)

  return (
    <div style={{ position: 'relative' }}>
      <div className={style.tokens} id="hp-tokens">
        {tokens.map((token) => (
          <div key={token.name} className={style.token}>
            <div className={style.name}>
              {token.name} ({token.symbol})
            </div>
            <div className={style.price}>
              {formatNumber(token.price, { precision: 'auto' })} USD
            </div>
            <div className={style.change}>24h: {formatPercentage(token.percent_change_24h)}</div>
            <div className={style.marketCap}>Market Cap: ${abbreviateNumber(token.market_cap)}</div>
            {token.history && (
              <div className={style.graph}>
                <div className={style.graphWeek}>Past Week</div>
                <PriceGraphMemo
                  prices={token.history
                    .filter((_, i) => i % Math.ceil(token.history!.length / 40) === 0)
                    .map((h) => h.px)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div
        className={style.button}
        onClick={() => {
          document
            .getElementById('hp-tokens')
            ?.scrollBy({ left: isMax767 ? -352 : -520, behavior: 'smooth' })
        }}
      >
        <span style={{ transform: 'rotate(180deg) translateY(2px)' }}>
          <Arrow width={16} color="#9fe870" />
        </span>
      </div>
      <div
        className={style.button}
        onClick={() => {
          document
            .getElementById('hp-tokens')
            ?.scrollBy({ left: isMax767 ? 352 : 520, behavior: 'smooth' })
        }}
      >
        <Arrow width={16} color="#9fe870" />
      </div>
    </div>
  )
})

const graphWidth = 200
const graphHeight = 40

const PriceGraphMemo = observer(({ prices }: { prices: number[] }) => {
  if (prices.length < 2) return null

  const minPrice = Math.min(...prices)
  const maxPrice = Math.max(...prices)

  const points = prices.map((price, i) => {
    const left = (graphWidth / (prices.length - 1)) * i
    const top = graphHeight - ((price - minPrice) / (maxPrice - minPrice)) * graphHeight
    return `${left},${top}`
  })

  return (
    <svg
      viewBox={`0 0 ${graphWidth} ${graphHeight}`}
      style={{ width: graphWidth, height: graphHeight }}
    >
      <polyline fill="none" stroke="#fff" strokeWidth="2" points={points.join(' ')} />
    </svg>
  )
})
