import { BrowserRouterLoader } from 'browser-router'
import Footer from 'components/footer'
import { generateFingerprintIfStale } from 'fp'
import Home from 'pages'
import About from 'pages/about'
import Contact from 'pages/contact'
import Jobs from 'pages/jobs'
import JobsSmartContract from 'pages/jobs/smart-contract'
import PrivacyPolicy from 'pages/privacy-policy'
import CryptoWallets from 'pages/products/crypto-wallets'
import MarketDataPlatform from 'pages/products/market-data-platform'
import MatchingEngine from 'pages/products/matching-engine'
import TradeExecutionAnalytics from 'pages/products/trade-execution-analytics'
import TOS from 'pages/tos'
import { useEffect, useRef } from 'react'
import { BrowserRouter, Route, Routes, useLocation, useNavigationType } from 'react-router-dom'
import 'styles/a_normalize.scss'
import 'styles/aa_globals.scss'
import style from 'styles/app.module.scss'
import 'styles/globe.scss'

generateFingerprintIfStale()
setInterval(generateFingerprintIfStale, 1000 * 60 * 5)

export default function App() {
  return (
    <BrowserRouter>
      <BrowserRouterLoader>
        <div className={style.app}>
          <AppRoutes />
          <Footer />
        </div>
      </BrowserRouterLoader>
    </BrowserRouter>
  )
}

const AppRoutes = () => {
  useScrollToTopOnRouteChange()

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/products/crypto-wallets" element={<CryptoWallets />} />
      <Route path="/products/matching-engine" element={<MatchingEngine />} />
      <Route path="/products/trade-execution-analytics" element={<TradeExecutionAnalytics />} />
      <Route path="/products/market-data-platform" element={<MarketDataPlatform />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/jobs/principal-smart-contract-developer" element={<JobsSmartContract />} />
      <Route path="/terms-of-service" element={<TOS />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  )
}

export const useScrollToTopOnRouteChange = () => {
  const { pathname } = useLocation()
  const navigationType = useNavigationType()
  const lastPathname = useRef<string>('')

  useEffect(() => {
    if (navigationType !== 'POP' && lastPathname.current !== pathname) {
      window.scrollTo(0, 0)
    }
    lastPathname.current = pathname
  }, [pathname, navigationType])
}
